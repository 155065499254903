<template>
  <section class="container">
    <div class="d-flex my-5">
        <div style="flex-grow:3">
            <h1>Results</h1>
            <!-- maybe should say "Fragrance Recommendations?"-->
        </div>
        <div class="mt-1" style="">
            <label for="txtPermalink" class="me-2" style="font-size:0.8em;">Permalink</label><br/>
            <input v-on:focus="$event.target.select()" id="txtPermalink" type="text" ref="permalink" readonly :value="getPermalink()" style="font-size: 0.8em;" />
            <span class="text-secondary ms-2" @click="copyPermalink"><BIconClipboardPlus /></span>
        </div>
    </div>
    
    <div class="row">
        <div v-for="(fragrance, index) in currentRecommendedFragrances.slice(0, 6)" :key="fragrance.id" class="col-md-6 col-sm-12 mb-5">
            <div class="ratio ratio-16x9 mb-4 position-relative text-center">
                <img :src="fragrance.heroImageUrl" :alt="fragrance.shortDesc" style="width: 100%" />
                <a class="btn btn-primary centered align-self-center btn btn-secondary" target="_blank" :href="processPDFUrl(fragrance)" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);display: inline-block; padding: 8px 16px; background-color: #fff; color: var(--orange); width: initial; height: initial;">View</a>
                <!--<button class="centered align-self-center btn btn-secondary" @click="viewRecommendedFragrance(fragrance.id)" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);display: inline-block; padding: 8px 16px; background-color: #fff; color: var(--orange); width: initial; height: initial;">View</button>-->
            </div>
            <div class="text-center">
                <h3 class="notranslate">{{ fragrance.name }}</h3>
                <div class="d-flex align-items-center justify-content-center"><img :src="getIconUrlByFragrance(index)" width="16"><span class="ms-2">{{ getMatchTextByFragrance(index) }}</span></div>
                <div>{{ fragrance.shortDescription }}</div>
                <div>SKU: {{ fragrance.sku }}</div>
            </div>
        </div>
    </div>

    <!-- RECOMMENDED FRAGRANCE MODAL -->
    <div v-if="isRecommendedFragranceModalVisible" class="modal fade show" id="myModal" style="display: block;">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title notranslate">{{ recommendedModalFragrance.name }}</h4>
                    <button type="button" class="btn-close" @click="closeRecommendedFragranceModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body p-4">
                    <div class="row">
                        <div class="col">
                            <h2>About</h2>
                            <p id="RecommendedFragranceLongDescription">{{ recommendedModalFragrance.longDescription }}</p>
                        </div>
                    </div>
                    
                    <!-- adjective list -->
                    <div class="row">
                        <div class="col my-3">
                            <adjectiveDisplayList v-if="recommendedModalFragrance.adjectives.length > 0" :displayAdjectives="recommendedModalFragrance.adjectives"></adjectiveDisplayList>
                        </div>
                    </div>
                    

                    <div class="row">
                        <div class="col">
                            <!-- hero image -->
                            <img v-show="recommendedModalFragrance.heroImageUrl != null" :src="recommendedModalFragrance.heroImageUrl" class="img-hero ratio ratio-16x9" />
                        </div>
                        <div class="col px-4">
                            <!-- color palette -->
                            <div class="row" style="border: solid 1px var(--gray-light);">
                                <div v-for="color in recommendedModalFragrance.colors" :key="color" class="col" style="margin: 0; padding: 0;">
                                    <div :style="getStyleForSwatchColor(color)" class="modal-swatch-color"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeRecommendedFragranceModal" data-dismiss="modal">Close</button>
                    <a class="btn btn-primary" :href="recommendedModalFragrance.oneSheetPdfUrl">Download PDF</a>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isRecommendedFragranceModalVisible" class="modal-backdrop fade show"></div>

    <!-- ADMIN-ONLY SECTION -->
    <div v-if="showAdminSection">
        <div>
            <div class="text-center align-middle">
                <button @click="toggleAdminTools()" class="btn-txt-expand fs-5" aria-expanded="true" aria-controls="collapseAdmin">
                    <span class="align-text-top">
                        <Transition mode="out-in">
                            <BIconCaretDownFill v-if="showAdminTools" class="align-text-top" :fill="currentTheme == 'light' ? 'darkgray' : 'white'" />
                            <BIconCaretRightFill v-else-if="showAdminTools == false" class="align-text-top" :fill="currentTheme == 'light' ? 'darkgray' : 'white'" />
                        </Transition>
                        </span>
                    <span class="ms-2">Admin Tools</span>
                </button>
            </div>
            <Transition>
            <div v-if="showAdminTools" id="collapseAdmin">
                <div class="row">
                    <!-- DOWNLOAD FRAGRANCE RESULTS -->
                    <div class="col text-center">
                        <h3>Download Results</h3>
                        <p>Upload the clients fragrance matches for upload into Salesforce.</p>
                        <button @click="ExportResults" class="btn btn-primary">Download Results CSV</button>
                    </div>
                    <!-- DOWNLOAD QUIZ ANSWERS -->
                    <div class="col text-center">
                        <h3>Download Answers</h3>
                        <p>Upload the clients answers to each question for upload into Salesforce.</p>
                        <div>
                            <button @click="ExportAnswers" class="btn btn-primary">Download Answers CSV</button>
                        </div>
                    </div>
                </div>
            </div>
            </Transition>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            Result generated by {{ quizGeneratedBy }}
        </div>
    </div>
  </section>
</template>

<script>
import {mapGetters } from 'vuex';
import adjectiveDisplayList from '../../components/adjective-display-list.vue';
import { toRaw } from 'vue';
import {VueJsExcel} from 'vue-js-excel'

export default {
    name: 'Home',
    components: {
        adjectiveDisplayList
    },
    mixins: [VueJsExcel],
    data() {
        return {
            currentQuestions: [],
            currentQuizResult: {},
            currentProvider: {},
            currentExtendedUser: {},
            quizResultMetadata: {},
            quizGeneratedBy: '',
            adjectivesForAnswers: [],
            adjectivesForFragrances: [],
            currentRecommendedFragrances: [],
            showAdminSection: false,
            showAdminTools: false,
            isValidForm: true,
            isRecommendedFragranceModalVisible: false,
            recommendedModalFragrance: {},
        }
    },
    mounted() {
        this.initQuizResult();
    },
    computed: {
    ...mapGetters({
            currentTheme: 'uiModule/getCurrentTheme',
            quizResults: 'quizResultsModule/getQuizResults',
            publishedFragrancesExtended: 'fragrancesExtendedModule/getPublishedFragrancesExtended',
            allQuestions: 'questionsModule/getQuestions',
            isSignedInUser: 'isSignedInUser',
            getCurrentUser: 'getCurrentUser',
            getCurrentUserInRole: 'getCurrentUserInRole',
            providerDetailById: 'providersModule/getProviderById',
            providerDetailByName: 'providersModule/getProviderByName',
            account: 'getCurrentUser',
        })
    },
    methods: {
        processPDFUrl(fragrance) {
            if (!fragrance) return "";
            this.currentProvider = this.providerDetailByName(this.currentQuizResult?.project?.provider);
            if (!this.currentProvider?.showLogoOnPdf) {
                return fragrance.oneSheetPdfUrlWithoutLogo;
            } else {
                return fragrance.oneSheetPdfUrl;
            }
        },
        initQuizResult() {
            try {
                if (this.getCurrentUserInRole('Task.Admin')) {
                    this.showAdminSection = true;
                }
            } catch (error) {
                console.log(error);
            }
            
            let promisesToCall = [];
            // init: get active questions
            promisesToCall.push(this.$store.dispatch('questionsModule/fetchQuestions'));
            promisesToCall.push(this.$store.dispatch('providersModule/fetchProviders'));
            promisesToCall.push(this.$store.dispatch('fragrancesExtendedModule/fetchFragrancesExtended'));
            promisesToCall.push(this.$store.dispatch("quizResultsModule/fetchQuizResults"));
            if (promisesToCall.length > 0) {
                Promise.all(promisesToCall).then(() => {

                    // init: get state of current quiz result...if finished, redirect to results page
                    this.currentQuizResult = this.quizResults.find(result => result.id == this.$route.params.resultId);
      
                    if (!this.currentQuizResult.isQuizCompleted) {
                        this.goBackToQuiz();
                    } else {
                        this.setQuizMetadata();
                        this.getAdjectivesForAnswers();
                        this.getAdjectivesForFragrances();
                    }
                });
            } else {
                console.log('We already have data...move on to init phase...');
            }
        },
        initProviderDetails(providerName) {
            let _currentUserProvider = this.providerDetailByName(providerName);
            this.currentProvider = _currentUserProvider;
            //this.currentExtendedUser.provider = _currentUserProvider;
        },
        setQuizMetadata() {
            if (this.currentQuizResult != null) {
                if (this.currentQuizResult.invite != null && 
                this.currentQuizResult.invite.name != null && 
                this.currentQuizResult.invite.email != null) {
                    this.quizGeneratedBy = this.currentQuizResult.invite.name;
                    this.quizResultMetadata = {
                        filename: 'VseAnswers-' + this.currentQuizResult.invite.name.replaceAll(' ', '-'),
                        username: this.currentQuizResult.invite.name,
                        userEmail: this.currentQuizResult.invite.email,
                        salesperson: 'N/A - User Invite',
                        project: 'N/A - User Invite',
                        quizDate: this.currentQuizResult.quizCompleteDate
                    }
                    //this.initProviderDetails(this.currentQuizResult.invite.provider);
                    this.currentProvider = this.providerDetailById(this.currentQuizResult.invite.provider);
                } else if (this.currentQuizResult.project != null &&
                this.currentQuizResult.project.name != null &&
                this.currentQuizResult.project.salesperson != null) {
                    this.quizGeneratedBy = this.currentQuizResult.project.salesperson;
                    this.quizResultMetadata = {
                        filename: 'VseAnswers-' + this.currentQuizResult.project.name.replaceAll(' ', '-'),
                        username: 'N/A - Client Proposal',
                        userEmail: 'N/A - Client Proposal',
                        salesperson: this.currentQuizResult.project.salesperson,
                        project: this.currentQuizResult.project.name,
                        quizDate: this.currentQuizResult.quizCompleteDate
                    }
                    //this.initProviderDetails(this.currentQuizResult.project.provider);
                    this.currentProvider = this.providerDetailByName(this.currentQuizResult.project.provider);
                }
            }
        },
        getAdjectivesForAnswers() {
            // loop through all answers...if answer = a selected answer, add it's adjectives to the list
            var selectedAnswerIds = [];

            selectedAnswerIds = selectedAnswerIds.concat(this.currentQuizResult.questions.map(function(question) {
                let selected_answers = Object.assign({}, question.selectedAnswers);
                console.log(selected_answers);
                return toRaw(question.selectedAnswers);
            }));
            selectedAnswerIds = selectedAnswerIds.flat();
            var adjectivesWithCounts = [];
            this.allQuestions.forEach((question) => {
                question.answers.forEach((answer) => {
                    if (selectedAnswerIds.includes(answer.id)) {
                        adjectivesWithCounts = adjectivesWithCounts.concat(answer.adjectives.map(function(adjective) {
                            return adjective.id;
                        }));
                    }
                })
            });

            const occurrences = adjectivesWithCounts.reduce(function (acc, curr) {
            return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
            }, {});
            this.adjectivesForAnswers = occurrences;
        },
        getAdjectivesForFragrances() {
            // loop through all fragrances...if answer = a selected answer, add it's adjectives to the list
            var allPublishedFragrances = this.publishedFragrancesExtended;
            let providerFragranceIds = this.currentProvider.fragrances.map(({id}) => id);
            let providerPublishedFragrances = allPublishedFragrances.filter(fragrance => providerFragranceIds.includes(fragrance.id));
            // get subset of allowable fragrance inventory by provider from project or invite
    
            providerPublishedFragrances.forEach((fragrance) => {
                // loop through all adjectives...where matching an Answer Adjective...
                // adjective answer Count * adjective weight
                // add to cumulative adjective score for this fragrance
                var fragranceScore = 0;
                fragrance.adjectives.forEach((adjective) => {
                    let answerAdjectiveCount = this.adjectivesForAnswers[adjective.id];
                    if (answerAdjectiveCount !== undefined && adjective.weight !== undefined && adjective.weight > 0) {
                        fragranceScore += adjective.weight * answerAdjectiveCount;
                    }
                });
                fragrance.fragranceScore = fragranceScore;
                this.currentRecommendedFragrances.push(fragrance);
            });
            // sort in descending order by fragrance score
            this.currentRecommendedFragrances.sort((a, b) => b.fragranceScore - a.fragranceScore);
        },
        viewRecommendedFragrance(extFragranceId) {
            this.isRecommendedFragranceModalVisible = true;
            this.recommendedModalFragrance = this.publishedFragrancesExtended.find(frag => frag.id == extFragranceId);
        },
        closeRecommendedFragranceModal() {
            this.isRecommendedFragranceModalVisible = false;
        },
        getStyleForSwatchColor(color) {
            return 'background-color: ' + color + '; min-height: 80px; min-width: 10px;';
        },
        getPermalink() {
            return window.location.origin + '/quiz/' + this.$route.params.resultId + '/complete';
        },
        copyPermalink() {
            this.$refs.permalink.focus();
            document.execCommand('copy');
            this.$refs.permalink.blur();
        },
        getIconUrlByFragrance(index) {
            //var images = require.context('../assets/', false, /\.png$/);
            var iconSrc = 'good.png';
            if (index == 0) {
                iconSrc = 'best.png';
            } else if (index == 1) {
                iconSrc = 'great.png';
            }
            // return './assets/' + iconSrc; //best.png';
            //return images('./' + iconSrc + ".png");
            //var img = 'best.png';
            return require(`@/assets/${iconSrc}`);
        },
        getMatchTextByFragrance(index) {
            var matchText = 'GOOD MATCH';
            if (index == 0) {
                matchText = 'BEST MATCH';
            } else if (index == 1) {
                matchText = 'GREAT MATCH';
            }
            return matchText;
        },
        toggleAdminTools() {
            this.showAdminTools = this.showAdminTools ? false : true;
        },
        ExportResults() {
            let filename = this.quizResultMetadata.filename;
            let username = this.quizResultMetadata.username;
            let userEmail = this.quizResultMetadata.userEmail;
            let project = this.quizResultMetadata.project;
            let salesperson = this.quizResultMetadata.salesperson;
            let quizDate = this.quizResultMetadata.quizDate;
            let csvResults = this.currentRecommendedFragrances.map(({name, sku, fragranceScore}) => {
                return {username, userEmail, salesperson, project, quizDate, name, sku, fragranceScore};
            });
            let columnHeaders = ['User Name', 'User Email', 'Salesperson', 'Project', 'Quiz Date', 'Fragrance Name', 'Fragrance SKU', 'Score'];
            this.VueJsExcelExport(csvResults, filename, columnHeaders);
        },
        ExportAnswers() {
            let filename = this.quizResultMetadata.filename;
            let username = this.quizResultMetadata.username;
            let userEmail = this.quizResultMetadata.userEmail;
            let project = this.quizResultMetadata.project;
            let salesperson = this.quizResultMetadata.salesperson;
            let quizDate = this.quizResultMetadata.quizDate;
            let selectedAnswersFull = this.currentQuizResult.questions.map(({type, questionText, selectedAnswers, sourceAnswers}) => {
                // map to return just titles, then join on ','
                let _selectedAnswers = toRaw(selectedAnswers);
                let _answerTitles = sourceAnswers.filter(function(currentAnswer) {
                    return _selectedAnswers.includes(currentAnswer.id);
                }).map(ans => ans.title).join(',');
                return {username, userEmail, salesperson, project, quizDate, type, questionText, _answerTitles}
            });
            let columnHeaders = ['User Name', 'User Email', 'Salesperson', 'Project', 'Quiz Date', 'Question Type', 'Question Text', 'Selected Answers'];
            this.VueJsExcelExport(selectedAnswersFull, filename, columnHeaders);
        },
        goBackToQuiz() {
            this.$router.push('/quiz/' + this.currentQuizResult.id);
        },
    }
}
</script>

<style scoped>
.btn-txt-expand {
    background-color: transparent;
    border: none;
}
.light .btn-txt-expand {
    color: var(--gray-dark);
}
.dark .btn-txt-expand {
    color: var(--white);
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>