<template>
    <section id="ColorSwatchTray">
        <p id="ColorSwatchTrayHelpText">Add up to 8 colors. At least 3 are required.</p>
        <ul class="swatch-tray d-flex align-items-center">
            <li v-for="(selectedColor, index) in currentSwatchColors" v-bind:key="selectedColor" @click="mode == 'view' ? null : addColorToSwatch(index)" class="swatch swatch-select me-2" :class="mode != 'view' ? 'swatch-removable' : ''" :style="`background-color: ${selectedColor}`">
                <span v-if="mode != 'view'" @click.stop="removeItemFromSelectedList(index)" class="text-primary swatch-remove"><BIconTrashFill /></span>
                <span><img v-if="mode != 'view'" class="swatch-fill-icon" src="../assets/paint-bucket.png" alt="Add Color" /></span>
            </li>
            <li v-if="mode != 'view'" class="swatch swatch-empty" :class="mode != 'view' ? 'swatch-removable' : ''" @click="addSwatch"><img src="../assets/plus.png" class="" alt="Add Swatch" /></li>
        </ul>
    </section>
</template>

<script>

export default {
  name: 'ColorSwatchTray',
  props: ['activeHexColor', 'mode'],
  data() {
    return {
        // selectedColors: [ {color: "#345634"}],
        activeColorIndex: 0
    }
  },
  computed: {
    currentExtendedFragrance() {
        return this.$store.getters['fragrancesExtendedModule/getCurrentExtendedFragrance'];
    },
    currentSwatchColors() {
        //return this.selectedColors;
        return this.currentExtendedFragrance.colors;
    },
    currentSelectorColor() {
        return this.$parent.activeSelectorColor;
    }
  },
  methods: {
    addSwatch() {
        if (this.currentSwatchColors.length < 8) {
            //this.currentSwatchColors.push({ color: '#333'});
            this.$store.commit('fragrancesExtendedModule/ADD_CURRENT_FRAGRANCE_EXTENDED_COLOR', '#333');
        }
    },
    addColorToSwatch(index) {
        // get current color in color picker
        //var currentColorHex = '#6733F8';
        var currentColorHex = this.currentSelectorColor;
        this.$store.commit('fragrancesExtendedModule/UPDATE_CURRENT_FRAGRANCE_EXTENDED_COLOR', { index: index, color: currentColorHex });
    },
    removeItemFromSelectedList(index) {
        this.$store.commit('fragrancesExtendedModule/REMOVE_CURRENT_FRAGRANCE_EXTENDED_COLOR', index);
    }
  }
};
</script>