export function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function generateRandomCode(lengthInCharacters) {
    var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
    var result = ""
    var charactersLength = characters.length;

    for ( var i = 0; i < lengthInCharacters; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function formatToShortDate(date) {
    // let thisDate = new Date(date);
    // console.log(date);
    // console.log(thisDate);
    return date != null && date.length > 0 ? date.split('T')[0] : '-';
}