<template>
    <section>
        <adjectiveAdminSelectedList @removeItem="onItemRemoved" :selectedAdjectives="selectedAdjectives"></adjectiveAdminSelectedList>
        <adjectiveAdminList @addItem="onItemAdded" :selectedAdjectives="selectedAdjectives"></adjectiveAdminList>
    </section>
</template>
<script>
import adjectiveAdminSelectedList from './adjective-admin-list-selected.vue';
import adjectiveAdminList from './adjective-admin-list.vue';

export default {
  name: 'FragranceAdjectiveSelect',
  components: {
    adjectiveAdminSelectedList,
    adjectiveAdminList,
  },
  data() {
    return {
        activeAdjectiveIndex: 0
    }
  },
  computed: {
    selectedAdjectives() {
        return this.$store.getters['fragrancesExtendedModule/getCurrentExtendedFragrance'].adjectives;
    },
  },
  methods: {
    onItemAdded(newAdjective) {
        //this.selectedAdjectives.push(newAdjective);
        const index = this.selectedAdjectives.findIndex(x => x.id === newAdjective.id);
        if (index == undefined || index == -1) {
            //this.selectedAdjectives.splice(index, 1);
            this.$store.commit('fragrancesExtendedModule/ADD_CURRENT_FRAGRANCE_EXTENDED_ADJECTIVE', newAdjective);
            
        }
    },
    onItemRemoved(adjectiveId) {
        const index = this.selectedAdjectives.findIndex(x => x.id === adjectiveId);
        if (index !== undefined) {
            //this.selectedAdjectives.splice(index, 1);
            this.$store.commit('fragrancesExtendedModule/REMOVE_CURRENT_FRAGRANCE_EXTENDED_ADJECTIVE', index);
        } 
    }
  }
};
</script>