import axios from 'axios'; 

export default {
  namespaced: true,  
  state() {
    return {
        axiosProviderListConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'XgoiO6yq6hhrvq2PNrD6Hi1GfJ-hjWow459JKFzqz6poAzFu1BcPJg==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosAddVseProviderConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'Rkz81Crv9wnicG6EOHQVW4Vgl23QQ6NHxFJfTAPBZr1_AzFuXkT3qQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosUpdateVseProviderConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'0g0olKiNTBJ2SZyUMb_My2ulAlrrmLaV3UobF_CzVFQjAzFuXuRVtA==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosDeleteVseProviderConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'5t8ceJklt_GM--MYk0CpLGHkkAE3bHvylQaUlZ_Uua6SAzFuGKSNTw==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        providers: [],
        isProviderListCachable: true,
        providerFetchCount : 0,
        maxRetries : 1,
    };
  },
  getters: {
      getProviders(state) {
        if (state.providers != null && state.providers.length > 0) {
            console.log('State detected in getter. Length: ' + state.providers.length);
        }
        return state.providers;
      },
      getProviderById: (state) => (id) => {
        //console.log(state);
        //console.log(id);
          if (null != state.providers) {
            return state.providers.find(provider => provider.id === id);
          } else {
            return null;
          }   
      },
      getProviderByName: (state) => (name) => {
        //console.log(state);
        //console.log(id);
          if (null != state.providers) {
            return state.providers.find(provider => provider.name === name);
          } else {
            return null;
          }   
      },
      count: (state) => {
        if (state.providers == null) {
            return 0;
        } else {
            return state.providers.length;
        }   
      }
  },
  mutations: {
    SET_PROVIDERS(state, providers) {
        state.providers = providers;
    }, 
    ADD_PROVIDER(state, provider) {
        state.providers.push(provider);  
    },
    UPDATE_PROVIDER(state, provider) {
        const index = state.providers.findIndex(x => x.id === provider.id);
        if (index !== undefined) {
            state.providers[index].name = provider.name;
            state.providers[index].fragrances = provider.fragrances;
        }
    },
    DELETE_PROVIDER(state, providerId) {
        const index = state.providers.findIndex(x => x.id === providerId);
        if (index !== undefined) {
            state.providers.splice(index, 1);
        } 
    },
  },
  actions: {
    async fetchProviders({ commit, state }) {      
        try {
            //commit('contentLoading', true);
            return await axios.get('https://prolitecdataservices.azurewebsites.net/api/vseListProviders',state.axiosProviderListConfig)
            .then((response) => {
                if (response.status == 200) {
                commit('SET_PROVIDERS', response.data.items);
                return response.data.items;
                }
            })
            .catch(error => {
              state.providerFetchCount += 1;
                console.error('API Error.',error);
              if (state.providerFetchCount <= state.maxRetries) {
                  return this.fetchProviders({ commit, state });
                  }
                });              
            } finally {
            //commit('contentLoading', false);
            }
    },
    async addProvider({ commit, state }, provider) {
        var newProvider = {
            id: '',
            name: provider.name,
            fragrances: provider.fragrances
        }
        try {
            //commit('contentLoading', true);
            var postVseProviderData = {
                'name': provider.name,
                'fragrances': provider.fragrances,
                'showLogoOnPdf':provider.showLogoOnPdf
              };
            return await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseCreateProvider', postVseProviderData, state.axiosAddVseProviderConfig)
            .then((response) => {
              if (response.status == 200) {
                newProvider.id = response.data.id;
                commit('ADD_PROVIDER', newProvider);
                return newProvider.id;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async updateProvider({ commit, state }, provider) {
        try {
            //commit('contentLoading', true);
            var postVseProviderData = {
                'id': provider.id,
                'name': provider.name,
                'fragrances': provider.fragrances,
                'showLogoOnPdf': provider.showLogoOnPdf
              };
            await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseUpdateProvider', postVseProviderData, state.axiosUpdateVseProviderConfig)
            .then((response) => {
              if (response.status == 200) {
                commit('UPDATE_PROVIDER', provider);
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async deleteProvider({ commit, state }, providerId) {
        try {
            //commit('contentLoading', true);
            var vseProviderData = {
                'id': providerId
              };
              console.log(vseProviderData);
              console.log(state.axiosDeleteVseProviderConfig);
            await axios.delete('https://prolitecdataservices.azurewebsites.net/api/vseDeleteProvider', { headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'5t8ceJklt_GM--MYk0CpLGHkkAE3bHvylQaUlZ_Uua6SAzFuGKSNTw==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }, data: { id: providerId } })
            .then((response) => {
              if (response.status == 200) {
                commit('DELETE_PROVIDER', providerId);
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    }
  }
};