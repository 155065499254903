<template>
  <section v-if="getCurrentUserInRole('Task.Admin') | getCurrentUserInRole('Task.Manager')" id="FragranceList" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <div class="d-flex align-items-between">
        <div class="col">
            <h1>Fragrances</h1>
            <p>Click the fragrance name below to edit scent information.</p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4">
            <jumpNavigation></jumpNavigation>
        </div>
    </div>
    <div>
      <router-link :to="{ path: '/fragrances/add' }" class="btn btn-primary">Add Fragrance</router-link>
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Fragrance List -->
    <div v-else class="table-responsive">
        <table class="table table-striped">
        <thead>
            <tr>
                <th class="sortable" @click="sort('Name')">Name</th>
                <th>SKU</th>
                <th class="sortable" @click="sort('Collection')">Collection</th>
                <th>Odor Neutralizer</th>
                <th>Published</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="fragrance in sortedFragrances" :key="fragrance.id">
            <td><router-link v-if="fragrance.id != null && fragrance.name != null" :to="{ name: 'FragranceDetail', params: { fragranceId: fragrance.id }}" class="link link-primary notranslate">{{ fragrance.name }}</router-link></td>
            <td>{{ fragrance.sku }}</td>
            <td>{{ fragrance.collection }}</td>
            <td>{{ fragrance.isOdorNeutralizer }}</td>
            <td>{{ fragrance.publishState }}</td>
            </tr>
        </tbody>
        </table>
    </div>
  </section>
</template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import jumpNavigation from '../../components/jump-navigation.vue';
import {mapGetters } from 'vuex';

export default {
  name: 'FragranceList',
  components: {
    breadcrumbVue,
    jumpNavigation,
  },
  data() {
    return {
      crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Fragrances',
                url: '/fragrances'
      }],
      payload:  '',
      sortedFragrances: [],
      isLoading: false,
      currentSort: '',
      currentSortDir: 'asc'
    }
  },
  provide() {
    return {
    }
  },
  mounted() {
    // get from API
        this.isLoading = true;
        this.load();
  },
  computed: {
    ...mapGetters({
        fragrancesExtended: 'fragrancesExtendedModule/getFragrancesExtended',
        getCurrentUserInRole: 'getCurrentUserInRole',
    })
  },
  methods: {
    //...mapActions('')
    async load() {
        await this.$store.dispatch("fragrancesExtendedModule/fetchFragrancesExtended").then(() => {
            this.sortedFragrances = this.fragrancesExtended;
            this.getSortedFragrances();
            this.isLoading = false;
        });
    },
    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;

      this.sortedFragrances = this.getSortedFragrances();
    },
    getSortedFragrances:function() {
      return this.sortedFragrances.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
};
</script>
