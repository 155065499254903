<template>
  <router-link to="/" class="navbar-brand">
    <!-- Loading spinner -->
    <div v-if="isLoading" class="d-flex justify-content-center mt-3">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- User's Logo or Default Logo -->
    <img v-else-if="isUserSignedIn && logoUrl" :src="logoUrl" alt="Prolitec logo" width="181" />
    <img v-else-if="currentTheme === 'light'" src="../assets/Prolitec_Grey_tag_Ambient.png" alt="Prolitec logo" width="181" />
    <img v-else-if="currentTheme === 'dark'" src="../assets/prolitec-reverse-white.png" alt="Prolitec logo" width="181" />
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderBarBrand',
  data() {
    return {
      currentExtendedUser: {},
      logoUrl: "",
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      currentTheme: 'uiModule/getCurrentTheme',
      isUserSignedIn: 'isSignedInUser',
      getUserByEmailAddress: 'usersModule/getUserByEmailAddress',
      account: 'getCurrentUser'
    })
  },
  watch: {
    isUserSignedIn(newVal) {
      // Load user logo when user signs in
      if (newVal) {
        this.populateUserLogo();
      }
    }
  },
  mounted() {
    if (this.isUserSignedIn) {
      this.populateUserLogo();
    }

  },
  methods: {
    async populateUserLogo() {
      this.isLoading = true;
      try {
        if (this.account && this.account.username) {
          await this.$store.dispatch("usersModule/fetchAllUsers");
          this.currentExtendedUser = this.getUserByEmailAddress(this.account?.username);

          // Set user's logo or default
          if (this.currentExtendedUser?.logo) {
            this.logoUrl = this.currentExtendedUser.logo;
          }
        }
      } catch (error) {
        console.error("Error fetching user logo:", error);
      } finally {
        this.isLoading = false;
      }
    },
  }
};
</script>
