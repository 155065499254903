<template>
  <section id="UserList" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <h1>Add User</h1>
    <p>
      Enter new user information below. Remember to select a role to set
      permission level for use of the tool. Salespeople can simply use the tool.
      Managers can add salespeople for their organization, and view quiz history
      for their organization. Admins can access this dashboard. When finished,
      click “add user.”
    </p>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- User Form -->
    <form @submit.prevent="submitAddUser">
      <div class="row my-3">
        <div class="col">
          <button @click="returnToList" class="btn btn-secondary me-2">
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">
            <span>Add User</span>
            <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status"
              aria-hidden="true"></span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="inputFirstName" class="form-label">First Name</label>
          <input id="inputFirstName" v-model="user.firstName" type="text" class="form-control" placeholder="First name"
            aria-label="First name" />
        </div>
        <div class="col">
          <label for="inputLastName" class="form-label">Last Name</label>
          <input id="inputLastName" v-model="user.lastName" type="text" class="form-control" placeholder="Last name"
            aria-label="Last name" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="inputEmail" class="form-label">Email</label>
          <input id="inputEmail" v-model="user.email" type="email" class="form-control" placeholder="Email"
            aria-label="Email" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="selectProvider" class="form-label">Scent Provider</label>
          <select id="selectProvider" v-model="user.provider" class="form-select" aria-label="Select Provider"
            :disabled="enableProviderByRole()" @change="onProviderSelected">
            <option value="0" selected>Select a provider...</option>
            <option v-for="provider in providers" v-bind:key="provider.id" :value="provider.id">
              {{ provider.name }}
            </option>
          </select>
        </div>
        <div class="col">
          <label for="selectRole" class="form-label">Role</label>
          <select id="selectRole" v-model="user.role" class="form-select" aria-label="Select role">
            <option value="0" selected>Select a role...</option>
            <option v-bind:value="groupConstants.user">Salesperson</option>
            <option v-if="getCurrentUserInRole('Task.Admin')" v-bind:value="groupConstants.manager">
              Manager
            </option>
            <option v-if="getCurrentUserInRole('Task.Admin')" v-bind:value="groupConstants.admin">
              Admin
            </option>
          </select>
        </div>
      </div>
      <br />
      <br />
      <div class="d-flex align-items-center" v-if="showChooseLogo">
        <label for="selectLogo" class="form-label me-3">Choose Logo :</label>
        <button type="button" class="btn btn-primary btn-sm" @click="fetchImages(getProviderName(user.provider))">
          Open Gallery
        </button>
        <div v-if="finalSelectedImage">
          <div class="h-[2rem] w-[2rem]">
            <img :src="finalSelectedImage" :alt="'Image not found'"
              class="w-100 shadow-1-strong rounded mb-2 gallery-img" />
          </div>
        </div>
      </div>

      <!-- Gallery Modal -->
      <div class="modal fade" id="galleryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="galleryModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="galleryModalLabel">
                Select an Image
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="max-height: 60vh; overflow-y: auto">
              <div v-if="isLoading" class="d-flex justify-content-center mt-3">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <!-- Gallery -->
              <div v-if="images.length === 0" class="text-center">
                <p>No image preview available</p>
              </div>
              <div v-else class="image-gallery-container">
                <div v-for="(image, index) in images" :key="index" class="image-item">
                  <div class="position-relative">
                    <!-- Radio Button for Selection -->
                    <input type="radio" :id="'image-' + index" v-model="selectedImage" :value="image.url"
                      class="position-absolute top-0 start-0 m-0" style="z-index: 2" />
                    <!-- Image -->
                    <img :src="image.url" :alt="`Image ${index}`" class="gallery-img" @click="selectImage(image.url)" />
                    <!-- Optionally, you can show a border or highlight the selected image -->
                    <div v-if="selectedImage === image.url" class="position-absolute top-0 start-0 w-100 h-100"></div>
                  </div>
                </div>
              </div>
              <!-- Gallery -->
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="confirmSelection">
                Select Image
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal"
      :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
  </section>
</template>

<script lang="js">
import breadcrumbVue from "../../components/breadcrumb.vue";
import errorMessageModal from '../../components/error-message-modal.vue';
import { mapGetters } from "vuex";
import { SendEmail } from '../../utils/email-service.js';
import { VUE_APP_VSE_USER_GROUP_ID, VUE_APP_VSE_MANAGER_GROUP_ID, VUE_APP_VSE_ADMIN_GROUP_ID } from "../../utils/constants.js";
//import _ from 'lodash';
import axios from 'axios';
import { Modal } from 'bootstrap';
export default {
  components: {
    breadcrumbVue,
    errorMessageModal
  },
  data() {
    return {
      crumbs: [
        {
          name: "Dashboard",
          url: "/dashboard",
        },
        {
          name: "Users",
          url: "/users",
        },
        {
          name: "Add User",
          url: "/users/add",
        },
      ],
      groupConstants: {
        user: VUE_APP_VSE_USER_GROUP_ID,
        manager: VUE_APP_VSE_MANAGER_GROUP_ID,
        admin: VUE_APP_VSE_ADMIN_GROUP_ID,
      },
      user: {
        firstName: "",
        lastName: "",
        email: "",
        provider: {},
        role: "0",
        activeDirectoryMemberId: "",
        member: {},
        joined: null,
        exists: false,
        isProlitec: false,
        logo: ""
      },
      savedUser: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        provider: {},
        role: "0",
        activeDirectoryMemberId: "",
        member: {},
        joined: null,
      },
      currentExtendedUser: {},
      currentUserProvider: {},
      isLoading: false,
      isSaving: false,
      invalidInput: false,
      errorMessages: [],
      errorTitle: 'Error Adding User',
      showInvalidForm: false,
      images: [],
      selectedImage: "",
      modal: null,
      finalSelectedImage: "",
      showChooseLogo: false,
    };
  },
  emits: ["add-user-submit"],
  computed: {
    ...mapGetters({
      account: 'getCurrentUser',
      isUserSignedIn: 'isSignedInUser',
      getCurrentUserInRole: "getCurrentUserInRole",
      getUserByEmailAddress: "usersModule/getUserByEmailAddress",
      getDisplayRole: "usersModule/getDisplayRole",
      providers: "providersModule/getProviders",
      providerDetail: 'providersModule/getProviderById',
    }),
  },
  mounted() {
    this.currentExtendedUser = this.getUserByEmailAddress(this.account.username);
    this.initAddUser();
  },
  methods: {
    async fetchImages(provider) {
      this.modal = new Modal(document.getElementById('galleryModal'))
      this.modal.show();
      this.isLoading = true;
      try {
        const response = await axios.get(`https://prolitecdataservices.azurewebsites.net/api/vseGetUserLogo?Provider=${provider}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'x-functions-key': 'aT9mFFAfdQyUECvcY4Td2MOqDzZGkCkCLfd87hh1NheIAzFuco4XCQ==',
              'Referrer-Policy': 'origin-when-cross-origin',
            }
          });
        this.images = response.data.images;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    },
    selectImage(image) {
      this.selectedImage = image;
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    confirmSelection() {
      if (this.selectedImage) {
        //   alert(`Selected Image: ${this.selectedImage}`);
        this.modal.hide();
        this.finalSelectedImage = this.selectedImage;
        // this.user.logo = this.finalSelectedImage.split('?')[0];
        this.user.logo = this.finalSelectedImage;

      }
      else {
        alert("Please select an image!");
      }
    },
    getProviderName(providerId) {
      const selectedProvider = this.providers.find(provider => provider.id === providerId);
      return selectedProvider ? selectedProvider.name : '';
    },
    onProviderSelected() {
      this.finalSelectedImage = null;
      this.selectedImage = null;
      this.showChooseLogo = this.user.provider !== '0';
    },
    initAddUser() {
      // if user = manager, set Provider and set to disabled
      if (this.getCurrentUserInRole('Task.Manager') && !this.getCurrentUserInRole('Task.Admin')) {
        this.currentUserProvider = this.providerDetail(this.currentExtendedUser.provider);
        this.user.provider = this.currentUserProvider.id;
      } else {
        this.user.provider = '0';
      }
    },
    enableProviderByRole() {
      if (this.getCurrentUserInRole('Task.Admin')) {
        return false;
      } else {
        return true;
      }
    },
    async submitAddUser() {
      this.errorMessages = [];
      var isValid = true;

      // check for text in short, long description
      if (this.user.firstName == null || this.user.firstName.length == 0) {
        isValid = false;
        this.errorMessages.push("First Name is required.");
      }
      if (this.user.lastName == null || this.user.lastName.length == 0) {
        isValid = false;
        this.errorMessages.push("Last Name is required.");
      }
      // check for selected family, collection
      if (this.user.email == null || this.user.email.length == 0) {
        isValid = false;
        this.errorMessages.push("Email is required.");
      }
      if (this.user.provider == null || this.user.provider.length == 0) {
        isValid = false;
        this.errorMessages.push("Provider is required.");
      }
      if (this.user.role == null || this.user.role.length == 0 || this.user.role == '0') {
        isValid = false;
        this.errorMessages.push("Select a valid role.");
      }

      let existingVseUser = this.getUserByEmailAddress(this.user.email);
      let userVseExists = existingVseUser != null && existingVseUser.id != null && existingVseUser.id.length > 0 ? true : false;
      if(userVseExists)
      {  
        isValid = false;
        this.errorMessages.push("User already exists with given email address."); 
      }
      // check if prolitec user
      if (this.user.email.split("@")[1] == "prolitec.com") {
        this.user.isProlitec = true;
      }
      if (isValid) {
        this.isSaving = true;
        // check if existing user in Azure AD
        this.$store.dispatch("usersModule/findExistingAdUser", this.user).then((adUser) => {
          let promisesToCall = [];
          if (adUser != null && adUser.id.length > 12) {
            this.user.activeDirectoryMemberId = adUser.id;
            this.user.member = adUser;
            this.user.joined = new Date();
            // promise 0, need return value
            // user exists in AD...if user exists in Vse.UserSettings, update...else add
            // promise 1 -- update user roles, no return value
            this.$store.dispatch("usersModule/addUserSettings", this.user).then(() => {
              this.$store.dispatch("usersModule/updateUserRoles", this.user).then(() => {
                var userId = '';
                userId = this.user.id;
                this.isSaving = false;
                this.sendInviteEmail(this.user);
                // redirect to view user with success message
                this.$router.push("/users/" + userId);
              }).catch((error) => {
                console.error("API Error, could not add user. Please try again later.", error);
                isValid = false;
                this.isSaving = false;
                this.errorMessages.push("API Error, could not add user. Please try again later.");
                this.showInvalidForm = true;
                this.clearFormFields();
              });
            }).catch((error) => {
              console.error("API Error, could not add user. Please try again later.", error);
              isValid = false;
              this.isSaving = false;
              this.errorMessages.push("API Error, could not add user. Please try again later.");
              this.showInvalidForm = true;
              this.clearFormFields();
            });
          } else {
            // invite user and get ID
            this.$store.dispatch("usersModule/inviteUser", this.user).then((newUser) => {
              // user memberId to add to groups
              this.user.activeDirectoryMemberId = newUser.id;
              this.user.joined = new Date();
              // promise 0
              promisesToCall.push(this.$store.dispatch("usersModule/addUserSettings", this.user));
              // promise 1, no return value needed
              promisesToCall.push(this.$store.dispatch("usersModule/updateUserRoles", this.user));
              // }
              Promise.all(promisesToCall).then((results) => {
                // redirect to view user with success message
                var userId = '';
                userId = results[0].id;
                this.sendInviteEmail(this.user);
                this.isSaving = false;
                this.$router.push("/users/" + userId);
              });
            })
              .catch((error) => {
                console.error("API Error, could not add user. Please try again later.", error);
                isValid = false;
                this.errorMessages.push("API Error, could not add user. Please try again later.");
                this.showInvalidForm = true;
              });
            }          
        });
      } else {
        this.showInvalidForm = true;
      }
    },
    clearFormFields() {
      // reset form fields
      this.user.firstName = "";
      this.user.lastName = "";
      this.user.email = "";
      this.user.provider = "";
      this.user.role = "0";
    },
    onCloseErrorMessageModal() {
      this.showInvalidForm = false;
      this.errorMessages = [];
    },
    returnToList() {
      this.$router.push('/users/');
    },
    async sendInviteEmail(user) {
      // send invite email
      var baseUrl = window.location.origin;
      var emailBody = `Dear ${user.firstName}, \n You have been invited to the Prolitec Virtual Scent Explorer application. Please visit our site at ${baseUrl} and select Sign In. If you do not already have a Microsoft account associated with this email address, you can create one.`;
      var emailInvite = {
        recipient: user.email,
        subject: 'You are invited to the Virtual Scent Expert Application',
        plainText: emailBody,
      };
      var messageId = await SendEmail(emailInvite).then(() => {
        console.log(messageId);
      });

    }
  },
};
</script>
<style scoped>
/* Scrollable Gallery Container */
.image-gallery-container {
  display: flex;
  /* Use flex layout */
  flex-direction: row;
  /* Arrange images in a row */
  flex-wrap: wrap;
  /* Allow images to wrap to the next line */
  gap: 30px;
  /* Space between images */
  padding: 10px;
  /* Padding around the container */
  max-height: 200px;
  /* Set max height for the container to trigger scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
}

/* Styling for each image */
.image-item {
  width: 150px;
  /* Set a fixed width for each image item */
  height: auto;
  /* Maintain aspect ratio */
  display: flex;
  /* Use flex for positioning the content inside each item */
  position: relative;
  /* Needed for absolute positioning of the radio button */
  flex-shrink: 0;
  /* Prevent shrinking of the image */
}

/* Image Styling */
.gallery-img {
  width: 100%;
  /* Make the image fill the container width */
  height: auto;
  /* Maintain aspect ratio */
  object-fit: cover;
  /* Ensure the image covers the space without distortion */
  border-radius: 8px;
  /* Optional: rounded corners */
  cursor: pointer;
  /* Change cursor to indicate clickable image */
  transition: transform 0.2s ease-in-out;
  /* Smooth transition on hover */
}

/* Hover Effect (Optional) */
.gallery-img:hover {
  transform: scale(1.1);
  /* Slightly enlarge the image on hover */
}

/* Positioning for the radio button */
input[type="radio"] {
  z-index: 2;
  /* Make sure the radio button is on top of the image */
  cursor: pointer;
  position: absolute;
  /* Position the radio button on top of the image */
  top: 10px;
  left: 10px;
}
</style>
