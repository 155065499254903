<template>
    <section>
        <colorSwatchTray selectedColors="selectedColors"></colorSwatchTray>
        <colorPicker @colorChange="onColorSelectorChange"></colorPicker>
    </section>
</template>
<script>
import colorPicker from './fragrance-color-picker.vue';
import colorSwatchTray from './fragrance-color-view.vue';

export default {
  name: 'FragranceColorSelect',
  components: {
    colorPicker,
    colorSwatchTray,
  },
  data() {
    return {
        activeSwatchIndex: 0,
        activeSelectorColor: '#333',
        formVisible: false
    }
  },
  methods: {
    addSwatch() {
        this.formVisible = this.formVisible == true ? false : true;
    },
    addColorToSwatch() {
        this.formVisible = false;
    },
    onColorSelectorChange(newVal) {
        this.activeSelectorColor = newVal;
    }
  }
};
</script>