<template>
  <section v-if="getCurrentUserInRole('Task.Admin') | getCurrentUserInRole('Task.Manager')" id="AdjectiveList" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <div class="d-flex align-items-between">
        <div class="col">
            <h1>Adjectives ({{ count }})</h1>
            <p>Edit adjectives by clicking the pencil icon. 
                Delete adjectives using the trash icon. 
                Add adjectives by clicking the “add” button. </p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4">
            <jumpNavigation></jumpNavigation>
        </div>
    </div>
    <div v-if="showSaveSuccess == true">
        <div class="alert fade show alert-success" role="alert">
        Your changes were saved.
        </div>
    </div>
    <div>
      <AdjectiveAdminAdd @showSaveSuccess="showSaveSuccessMessage"></AdjectiveAdminAdd>
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Adjective List -->
    <ul v-else class="list-unstyled card-columns">
        <li v-for="adjective in adjectives" :key="adjective.id" class="card p-3 mb-2">
            <!-- Name of Adjective -->
            <div class="link link-primary d-flex justify-content-between align-items-center">
                <div>
                    <span class="ms-2">{{ adjective.name }}</span>
                </div>
                <div>
                    <a @click="showEditModal(adjective.id)" class="me-2 text-primary"><BIconPencilFill /></a>
                    <a @click="showDeleteModal(adjective.id)"><BIconTrashFill variant="danger" /></a>
                </div>
            </div>
            <!--<router-link :to="{ name: 'AdjectiveDetail', params: { adjectiveId: adjective.id }}" class="link link-primary">{{ adjective.name }}</router-link>-->
            <!-- Action column (optional) -->
        </li>
    </ul>
    
    <!-- EDIT ADJECTIVE MODAL -->
    <div v-if="isEditModalVisible" class="modal fade show" id="myEditModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Edit Adjective</h4>
                    <button type="button" class="btn-close" @click="closeEditModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label for="inputEditName" class="form-label">Name</label>
                    <input id="inputEditName" v-model="updatedAdjectiveText" type="text" class="form-control" placeholder="Name" aria-label="Name">
                    <!--<p id="AdjectiveEditId">{{ this.selectedAdjective != null && this.selectedAdjective.id != null ? this.selectedAdjective.id : '' }}</p>-->
                    <ul v-if="showInvalidForm == true">
                        <li v-for="error in errorMessages" v-bind:key="error" class="text-danger">{{ error }}</li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" @click="saveUpdatedAdjective" data-dismiss="modal">     
                        <span>Save</span>
                        <span v-show="isAdjectiveUpdating" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-danger" @click="deleteSelectedAdjective" data-dismiss="modal">
                        <span>Delete</span>
                        <span v-show="isAdjectiveDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="closeEditModal" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isEditModalVisible" class="modal-backdrop fade show"></div>

    <!-- DELETE ADJECTIVE MODAL -->
    <div v-if="isDeleteModalVisible" class="modal fade show" id="myDeleteModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Delete Adjective</h4>
                    <button type="button" class="btn-close" @click="closeDeleteModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p id="FragranceDeleteText">Are you sure you want to delete <strong>{{ selectedAdjective.name }}</strong>?</p>
                    <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeDeleteModal" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="deleteSelectedAdjective" data-dismiss="modal">
                        <span>Confirm</span>
                        <span v-show="isAdjectiveDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isDeleteModalVisible" class="modal-backdrop fade show"></div>
    <!-- END: DELETE ADJECTIVE MODAL -->
  </section>
</template>

<script>
//import axios from 'axios'; // at the start of your <script> tag, before you "export default ..."
import breadcrumbVue from '../../components/breadcrumb.vue';
import jumpNavigation from '../../components/jump-navigation.vue';
import { mapGetters } from 'vuex';
import AdjectiveAdminAdd from '@/components/adjective-admin-add.vue';

export default {
  name: 'AdjectiveList',
  components: {
    breadcrumbVue,
    AdjectiveAdminAdd,
    jumpNavigation,
  },
  data() {
    return {
      crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Adjectives',
                url: '/adjectives'
      }],
      payload:  '',
      currentSort: '',
      currentSortDir: 'asc',
      selectedAdjective: null,
      updatedAdjectiveText: '',
      isEditModalVisible: false,
      isDeleteModalVisible: false,
      isAdjectiveUpdating: false,
      isAdjectiveDeleting: false,
      errorMessages: [],
      showInvalidForm: false,
      showSaveSuccess: false
    }
  },
  provide() {
    return {
    }
  },
  mounted() {
    // get from API
    if (this.$store.getters.getAdjectives == null) {
        this.load();
    }
  },
  computed: {
    ...mapGetters({
        adjectives: 'adjectivesModule/getAdjectives',
        getCurrentUserInRole: 'getCurrentUserInRole',
        count: 'adjectivesModule/count'
    })
  },
  methods: {
    //...mapActions('')
    async load() {
        await this.$store.dispatch("adjectivesModule/fetchAdjectives");
    },
    showEditModal(adjectiveId) {
        this.showSaveSuccess = false;
        // get adjective by ID
        this.errorMessages = [];
        this.selectedAdjective = this.adjectives.find(item => item.id == adjectiveId);
        this.updatedAdjectiveText = this.selectedAdjective.name;
        this.isEditModalVisible = true;
    },
    closeEditModal() {
        this.updatedAdjectiveText = '';
        this.isEditModalVisible = false;
        this.selectedAdjective = null;
    },
    showDeleteModal(adjectiveId) {
        this.showSaveSuccess = false;
        this.selectedAdjective = this.adjectives.find(item => item.id == adjectiveId);
        this.isDeleteModalVisible = true;
    },
    closeDeleteModal() {
        this.selectedAdjective = null;
        this.isDeleteModalVisible = false;
    },
    async saveUpdatedAdjective() {
        this.errorMessages = [];
        var isValid = true;

        // check for text in adjective
        if (this.updatedAdjectiveText == null || this.updatedAdjectiveText.length < 1) {
            isValid = false;
            this.errorMessages.push('Adjective is required.');
        }

        if (isValid) {
            this.isAdjectiveUpdating = true;
            this.selectedAdjective.name = this.updatedAdjectiveText;
            await this.$store.dispatch("adjectivesModule/updateAdjective", this.selectedAdjective).then(() => {
                this.isAdjectiveUpdating = false;
                this.closeEditModal();
                this.showSaveSuccessMessage();
            }); 
        } else {
            this.showInvalidForm = true;
        }
            
    },
    showSaveSuccessMessage() {
        this.showSaveSuccess = true;
    },
    async deleteSelectedAdjective() {
        this.isAdjectiveDeleting = true;
        await this.$store.dispatch("adjectivesModule/deleteAdjective", this.selectedAdjective.id).then(() => {
            this.isAdjectiveDeleting = false;
            this.closeDeleteModal();
            this.closeEditModal();
        });
    },
  }
};
</script>
