import axios from 'axios'; 

export default {
  namespaced: true,
  state() {
    return {
        axiosInviteListConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'QUiKTCcRMwWz7Pceed5_D7guKdMKzDPBpoWqlCzVb8MTAzFuyF9aMw==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosAddVseInviteConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'kuUC1fiwrOINxliTcESHDLxtY4D8NP2pk-PfbRAF_LUHAzFuCiuGQw==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosUpdateVseInviteConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'DGyBnO3VL-SUSO_aZy2B5DvGl_uJP9jxKgNkVXHoXmqDAzFudHl64g==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosDeleteVseInviteConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'uNpn_ZlVte32KWJPV-ANlcEb9WwjMaYtrsAxaI0PkvLAAzFulADsLw==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        invites: [],
        isInviteListCachable: true
    };
  },
  getters: {
    getInvites(state) {
        if (state.invites != null && state.invites.length > 0) {
            console.log('State detected in getter. Length: ' + state.invites.length);
        }
        return state.invites;
      },
    count: (state) => {
        if (state.invites == null) {
            return 0;
        } else {
            return state.invites.length;
        }
    },
    countByProvider: (state) => (providerId) => {
        if (null != state.invites) {
            let _invites = state.invites.filter(invite => invite.provider === providerId);
            return _invites != null && _invites.length > 0 ? _invites.length : 0;
        } else {
            console.log(state.invites);
            return 0;
        }
    },
    countBySalesperson: (state) => (salespersonEmail) => {
        if (null != state.invites) {
            var _invites = state.invites.filter(invite => invite.salesperson === salespersonEmail);
            return _invites != null && _invites.length > 0 ? _invites.length : 0;
        } else {
            console.log(state.invites);
            return 0;
        }
    }
  },
  mutations: {
    SET_INVITES(state, invites) {
        state.invites = invites;
    }, 
    ADD_INVITE(state, invite) {
        state.invites.push(invite);  
    },
    UPDATE_INVITE(state, invite) {
        const index = state.invites.findIndex(x => x.id === invite.id);
        if (index !== undefined) {
            state.invites[index].name = invite.name;
        }
    },
    DELETE_INVITE(state, inviteId) {
        const index = state.invites.findIndex(x => x.id === inviteId);
        if (index !== undefined) {
            state.invites.splice(index, 1);
        } 
    },
  },
  actions: {
    async fetchInvites({ commit, state }) {
        try {
            //commit('contentLoading', true);
            return await axios.get('https://prolitecdataservices.azurewebsites.net/api/vseListInvites',state.axiosInviteListConfig)
            .then((response) => {
                if (response.status == 200) {
                commit('SET_INVITES', response.data.items);
                return response.data.items;
                }
            })
            .catch(error => {
                console.error('API Error.',error);
                });
                //new Error(response.message || 'Failed to fetch requests.'));
            } finally {
            //commit('contentLoading', false);
            }    
    },
    async addInvite({ commit, state }, invite) {

        try {
            //commit('contentLoading', true);
            // var postVseInviteData = {
            //     'name': invite.name
            //   };
            return await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseCreateInvite', invite, state.axiosAddVseInviteConfig)
            .then((response) => {
              if (response.status == 200) {
                invite.id = response.data.id;
                commit('ADD_INVITE', invite);
                return invite.id;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async updateInvite({ commit, state }, invite) {
        try {
            //commit('contentLoading', true);
            // var postVseInviteData = {
            //     'id': invite.id,
            //     'name': invite.name
            //   };
            return await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseUpdateInvite', invite, state.axiosUpdateVseInviteConfig)
            .then((response) => {
              if (response.status == 200) {
                commit('UPDATE_INVITE', invite);
                return response.data;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async deleteInvite({ commit, state }, inviteId) {
        try {
            //commit('contentLoading', true);
            var vseInviteData = {
                'id': inviteId
              };
              console.log(vseInviteData);
              console.log(state.axiosDeleteVseInviteConfig);
            return await axios.delete('https://prolitecdataservices.azurewebsites.net/api/vseDeleteInvite', { headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'uNpn_ZlVte32KWJPV-ANlcEb9WwjMaYtrsAxaI0PkvLAAzFulADsLw==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }, data: { id: inviteId } })
            .then((response) => {
              if (response.status == 200) {
                commit('DELETE_INVITE', inviteId);
                return response.status;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    }
  }
};