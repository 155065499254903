export default {
    namespaced: true,
    state() {
        return {
            currentTheme: 'light'
        }; 
    },
    getters: {
        getCurrentTheme(state) {
            return state.currentTheme;
        }
    },
    actions: {
    },
    mutations: {
        TOGGLE_THEME(state) {
            state.currentTheme = state.currentTheme == 'light' ? 'dark' : 'light';
        }
    }
};