import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Emitter from 'tiny-emitter';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import Toast from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const app = createApp(App);
app.use(Toast, {
  position: 'top-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 60,
  hideProgressBar: false,
  theme: 'light',
});
app.config.globalProperties.$msalInstance = {};
// TODO: make the emitter a service
app.config.globalProperties.$emitter = new Emitter();

app.use(store).use(router).use(BootstrapIconsPlugin).mount('#app');
