
export const VUE_APP_MSAL_CLIENT_ID = "0ecdb0e8-0608-4508-afde-559bc90e1808";
export const VUE_APP_MSAL_KNOWN_AUTHORITY = "https://login.microsoftonline.com";
export const VUE_APP_MSAL_LOGIN_AUTHORITY = "https://login.microsoftonline.com/56de1282-f142-488c-a16f-9b36da679f96";
export const VUE_APP_MSAL_REDIRECT_URI_LOCAL_DEV = "https://localhost:8080";
export const VUE_APP_MSAL_REDIRECT_URI_TEST = "https://vse-test.prolitec.com";
export const VUE_APP_MSAL_REDIRECT_URI_PRODUCTION = "https://www.virtualscentexpert.com";
// UnComment below line while deploying dist folder directly using deployment token
//export const VUE_APP_MSAL_REDIRECT_URI_PRODUCTION = "https://agreeable-beach-0c5082b1e.5.azurestaticapps.net";
export const VUE_APP_VSE_USER_GROUP_ID = "b99ea58a-9913-4df8-8ec7-6b644dea4cd1";
export const VUE_APP_VSE_MANAGER_GROUP_ID = "f0a573b3-54fd-4e67-aca2-c4ba2b9d224c";
export const VUE_APP_VSE_ADMIN_GROUP_ID = "bcbb1cfb-0990-44cb-99a5-ac9444135b2b";
export const VUE_APP_COMMUNICATION_SERVICES_CONNECTION_STRING = "endpoint=https://vsecommunications.communication.azure.com/;accesskey=q18JmqQdFZKLmSrIBcm1AutMwNhfsD7ohZ5yPV7ELFYYMs2mUSWs9WramZE8irygCdrfI0gsLyH3WA99nxSpyA==";
// get constants based on environment - check process.env for local setting
// if detected, get from process.env, otherwise get from key vault
export function VUE_APP_MSAL_REDIRECT_URI() {
    if (process.env['NODE_ENV'] == 'development') {
        return VUE_APP_MSAL_REDIRECT_URI_LOCAL_DEV;
    } else if (process.env['NODE_ENV'] == 'test')  {
        return VUE_APP_MSAL_REDIRECT_URI_TEST;
    } else {
        return VUE_APP_MSAL_REDIRECT_URI_PRODUCTION;
    }
} 



