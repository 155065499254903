import axios from 'axios'; 

export default {
  namespaced: true,
  state() {
    return {
        axiosAdjectiveListConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'QXYK2yyUIaYBzZCFEAL2YSuecmRw//4FweQTYf0fE0ZAbeqI9iMfIQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosAddVseAdjectiveConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'21Xkdhqa3i2/lJvge8K2ofSld8Nmttso2h2KWefFEoGd0YDt0rX/ug==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosUpdateVseAdjectiveConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'mR0F78Bek8a4z7CGtUbGIlDW3aAQ7sI/VJaQnM4muB6bkB7LasB3tQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosDeleteVseAdjectiveConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'pEkhAa9zJI6e1jYeWWrVbdZuhd0vWglNIPwvGjcvpe1LYqbREP9DRg==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        adjectives: [],
        isAdjectiveListCachable: true
    };
  },
  getters: {
      getAdjectives(state) {
        if (state.adjectives != null && state.adjectives.length > 0) {
            console.log('State detected in getter. Length: ' + state.adjectives.length);
        }
        return state.adjectives;
      },
      count: (state) => {
        if (state.adjectives == null) {
            return 0;
        } else {
            return state.adjectives.length;
        }   
      }
  },
  mutations: {
    SET_ADJECTIVES(state, adjectives) {
        state.adjectives = adjectives;
    }, 
    ADD_ADJECTIVE(state, adjective) {
        state.adjectives.push(adjective);  
    },
    UPDATE_ADJECTIVE(state, adjective) {
        const index = state.adjectives.findIndex(x => x.id === adjective.id);
        if (index !== undefined) {
            state.adjectives[index].name = adjective.name;
        }
    },
    DELETE_ADJECTIVE(state, adjectiveId) {
        const index = state.adjectives.findIndex(x => x.id === adjectiveId);
        if (index !== undefined) {
            state.adjectives.splice(index, 1);
        } 
    },
  },
  actions: {
    async fetchAdjectives({ commit, state }) {
        try {
            //commit('contentLoading', true);
            return await axios.get('https://prolitecdataservices.azurewebsites.net/api/vseListAdjectives',state.axiosAdjectiveListConfig)
            .then((response) => {
                if (response.status == 200) {
                commit('SET_ADJECTIVES', response.data.items);
                return response.data.items;
                }
            })
            .catch(error => {
                console.error('API Error.',error);
                });
                //new Error(response.message || 'Failed to fetch requests.'));
            } finally {
            //commit('contentLoading', false);
            }
    },
    async addAdjective({ commit, state }, adjective) {
        var newAdjective = {
            id: '',
            name: adjective.name
        }
        try {
            //commit('contentLoading', true);
            var postVseAdjectiveData = {
                'name': adjective.name
              };
            await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseCreateAdjective', postVseAdjectiveData, state.axiosAddVseAdjectiveConfig)
            .then((response) => {
              if (response.status == 200) {
                newAdjective.id = response.data.id;
                commit('ADD_ADJECTIVE', newAdjective);
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async updateAdjective({ commit, state }, adjective) {
        try {
            //commit('contentLoading', true);
            var postVseAdjectiveData = {
                'id': adjective.id,
                'name': adjective.name
              };
            await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseUpdateAdjective', postVseAdjectiveData, state.axiosUpdateVseAdjectiveConfig)
            .then((response) => {
              if (response.status == 200) {
                commit('UPDATE_ADJECTIVE', adjective);
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async deleteAdjective({ commit }, adjectiveId) {
        try {
            //commit('contentLoading', true);
            await axios.delete('https://prolitecdataservices.azurewebsites.net/api/vseDeleteAdjective', { headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'pEkhAa9zJI6e1jYeWWrVbdZuhd0vWglNIPwvGjcvpe1LYqbREP9DRg==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }, data: { id: adjectiveId } })
            .then((response) => {
              if (response.status == 200) {
                commit('DELETE_ADJECTIVE', adjectiveId);
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    }
  }
};