<template>
  <section id="FragranceView" class="container">
    <div class="row mt-4">
        <breadcrumbVue :items="crumbs"></breadcrumbVue>
    </div>
    <div v-if="pageState != 'view'">
        <nav class="navbar navbar-edit navbar-expand fixed-top justify-content-between">
            <div class="container">
                <span>Edit Fragrance: <span class="notranslate">{{ currentExtendedFragrance.name }}</span></span>
                <span>
                    <button @click="showPageStateView" class="btn btn-secondary me-3">Cancel</button>
                    <button @click="validateAndSaveFragrance" class="btn btn-primary">
                        <span>Save</span>
                        <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </span> 
            </div>
        </nav>
    </div> 
    <div v-else>
        <h1 class="notranslate" style="margin-top:-.3em;">{{ currentExtendedFragrance.name }}</h1>
    </div>
    <div v-if="showSaveSuccess == true">
        <div class="alert fade show alert-success" role="alert">
        Your changes were saved.
        </div>
    </div>
    
    <div class="row mt-4">
        <div class="col">
            <label for="selectPublishState" class="form-label">Status</label>
            <select v-if="pageState != 'view'" id="selectPublishState" v-model="currentExtendedFragrance.publishState" class="form-select" aria-label="Visibility">
            <option disabled value="">Select an option</option>
            <option v-for="option in publishedStateOptions" v-bind:key="option" :value="option">{{ option }}</option>
            </select>
            <p v-else class="fs-5" :class="currentExtendedFragrance.publishState == 'Published' ? 'text-success' : 'text-danger'">{{ currentExtendedFragrance.publishState }}</p>
        </div>
        <div v-if="pageState == 'view'" class="col">
            <label>Visibility</label>
            <p class="fs-5">{{ calculatedVisibilityPercentage }}</p>
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <div id="FileUploadHeroImage" class="input-group flex-column align-items-start mb-3">
                <label class="my-3" for="inputFileHeroImage">Hero Image</label>
                <img v-show="currentExtendedFragrance.heroImageUrl != null" :src="currentExtendedFragrance.heroImageUrl" class="img-hero" width="160" />
                <input type="file" class="upload upload-image visually-hidden" @change="uploadHeroImage"  accept=".jpg,.gif,.png" id="inputFileHeroImage">
                <label class="upload-status text-primary" id="labelHeroImageUploadStatus"></label>
                <label v-show="pageState != 'view'" class="input-group-text btn btn-primary mt-3" for="inputFileHeroImage">
                    <span>Upload</span>
                    <span v-show="isHeroImageLoading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                </label>
                <div> 
                    <span v-show="currentExtendedFragrance.heroImageUrl == null" class="text-danger">Please upload an image</span>
                </div>
                <!--<p>{{ downloadHeroImageUrl }}</p>-->
            </div>
        </div>
        <div class="col">
            <div id="FileUploadOneSheetPdf" class="input-group flex-column align-items-start mb-3">
                <label class="my-3" for="inputFileOneSheetPdf">One Sheet PDF</label>
                <input type="file" class="upload upload-pdf visually-hidden" @change="uploadOneSheetPdf"  accept=".pdf" id="inputFileOneSheetPdf">
                <a v-show="currentExtendedFragrance.oneSheetPdfUrl != null" :href="currentExtendedFragrance.oneSheetPdfUrl" class="link link-primary fs-5">{{ currentExtendedFragrance.oneSheetPdfFilename }}</a>
                <!--<label class="upload-status text-primary" id="labelOneSheetPdfUploadStatus"></label>-->
                <label v-show="pageState != 'view'" class="input-group-text btn btn-primary mt-3" for="inputFileOneSheetPdf">
                    <span>Upload</span>
                    <span v-show="isOneSheetPdfLoading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                </label>
                <div>
                    <span v-show="currentExtendedFragrance.oneSheetPdfUrl == null" class="text-danger">Please upload a pdf file</span>
                </div>
            </div>
        </div>

        <div class="col">
            <div id="FileUploadOneSheetPdfWithoutLogo" class="input-group flex-column align-items-start mb-3">
                <label class="my-3" for="inputFileOneSheetPdfWithoutLogo">One Sheet PDF Without Logo</label>
                <input type="file" class="upload upload-pdf visually-hidden" @change="uploadOneSheetPdfWithoutLogo"  accept=".pdf" id="inputFileOneSheetPdfWithoutLogo">
                <a v-show="currentExtendedFragrance.oneSheetPdfUrlWithoutLogo != null" :href="currentExtendedFragrance.oneSheetPdfUrlWithoutLogo" class="link link-primary fs-5">{{ currentExtendedFragrance.oneSheetPdfWithoutLogoFilename }}</a>
                <!--<label class="upload-status text-primary" id="labelOneSheetPdfUploadStatus"></label>-->
                <label v-show="pageState != 'view'" class="input-group-text btn btn-primary mt-3" for="inputFileOneSheetPdfWithoutLogo">
                    <span>Upload</span>
                    <span v-show="isOneSheetPdfWithoutLogoLoading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                </label>
                <div>
                    <span v-show="currentExtendedFragrance.oneSheetPdfUrlWithoutLogo == null" class="text-danger">Please upload a pdf file without logo</span>
                </div>
            </div>
        </div>

    </div>
    <div class="row my-3">
        <div class="col">
            <label for="inputName" class="form-label">Name</label>
            <input id="inputName" v-model="currentExtendedFragrance.name" type="text" class="form-control notranslate" placeholder="Name" aria-label="Name" :disabled="pageState == 'view'">
        </div>
        <div class="col">
            <label for="inputSku" class="form-label">SKU</label>
            <input id="inputSku" v-model="currentExtendedFragrance.sku" type="text" class="form-control" placeholder="Sku" aria-label="Sku"  :disabled="pageState == 'view'">
        </div>
        <div class="col">
            <label for="inputDetectionLevel" class="form-label">Detection Level</label>
            <input id="inputDetectionLevel" v-model="currentExtendedFragrance.detectionLevel" type="text" class="form-control" placeholder="Detection Level" aria-label="Detection Level" :disabled="pageState == 'view'">
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <label for="inputShortDescription" class="form-label">Short Description</label>
            <input id="inputShortDescription" v-model="currentExtendedFragrance.shortDescription" type="text" class="form-control" placeholder="Short Description" aria-label="Short Description" :disabled="pageState == 'view'">
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <label for="inputLongDescription" class="form-label">Long Description</label>
            <textarea id="inputLongDescription" v-model="currentExtendedFragrance.longDescription" type="text" rows="4" class="form-control" placeholder="Long Description" aria-label="Long Description" :disabled="pageState == 'view'"></textarea>
        </div>
    </div>
    <div class="row align-items-center my-3">    
        <div class="col">
            <label for="selectFamily" class="form-label">Family</label>
            <select id="selectFamily" v-model="currentExtendedFragrance.family" class="form-select" aria-label="Select family" :disabled="pageState == 'view'">
            <option value="0" selected>Select a family...</option>
            <option v-for="option in families" v-bind:key="option" :value="option">{{ option }}</option>
            </select>
        </div>
        <div class="col">
            <label for="selectCollection" class="form-label">Collection</label>
            <select id="selectCollection" v-model="currentExtendedFragrance.collection" class="form-select" aria-label="Select collection" :disabled="pageState == 'view'">
            <option value="0" selected>Select a collection...</option>
            <option v-for="option in collections" v-bind:key="option" :value="option">{{ option }}</option>
            </select>
        </div>
        <div class="col form-check pt-4">
            <input type="checkbox" che id="checkOdorNeutralizer" v-model="currentExtendedFragrance.isOdorNeutralizer" class="form-check-input ms-0" aria-label="Odor Neutralizer?" :disabled="pageState == 'view'">
            <label for="checkOdorNeutralizer" class="form-check-label ms-2">Odor Neutralizer</label>
        </div>
    </div>
    <!-- COLORS -->
    <div class="row my-5">
        <h2>Colors</h2>
        <fragranceColorSelect v-if="pageState != 'view'"></fragranceColorSelect>
        <colorSwatchTray v-else selectedColors="currentExtendedFragrance.colors" mode="view"></colorSwatchTray>
    </div>
    
    <!-- ADJECTIVES -->
    <adjectiveSelectedList v-if="pageState != 'view'"></adjectiveSelectedList>
    <adjectiveAdminSelectedList v-else :selectedAdjectives="currentExtendedFragrance.adjectives" mode="view"></adjectiveAdminSelectedList>

    <!-- ACTIONS -->
    <div class="row my-5">
        <div class="col">
            <h2>Actions</h2>
            <button @click="showPageStateEdit" class="btn btn-primary">Edit</button>
        </div>
    </div>

    <!-- DELETE -->
    <div class="row my-5">
        <div class="col">
            <h2>Danger Zone</h2>
            <button @click="deleteFragranceWarning" class="btn btn-danger">Delete</button>
        </div>
    </div>

    <!-- DELETE MODAL -->
    <div v-if="isDeleteModalVisible" class="modal fade show" id="myModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Delete Fragrance</h4>
                    <button type="button" class="btn-close" @click="closeDeleteModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p id="FragranceDeleteText">Are you sure you want to delete <strong class="notranslate">{{ currentExtendedFragrance.name }}</strong>?</p>
                    <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeDeleteModal" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="deleteFragrance" data-dismiss="modal">
                        <span>Confirm</span>
                        <span v-show="isDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isDeleteModalVisible" class="modal-backdrop fade show"></div>
    <!-- END: DELETE MODAL -->
    <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
  </section>
</template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import fragranceColorSelect from '../../components/fragrance-color-select.vue';
import colorSwatchTray from '../../components/fragrance-color-view.vue';
import adjectiveSelectedList from '../../components/fragrance-adjective-select.vue';
import adjectiveAdminSelectedList from '../../components/adjective-admin-list-selected.vue';
import errorMessageModal from '../../components/error-message-modal.vue';
import { uploadFile } from '../../utils/files.js';
import {mapGetters } from 'vuex';

export default {
    name: 'FragranceDetail',
    components: {
        breadcrumbVue,
        fragranceColorSelect,
        colorSwatchTray,
        adjectiveSelectedList,
        adjectiveAdminSelectedList,
        errorMessageModal
    },
    data() {
    return {
        crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Fragrances',
                url: '/fragrances'
        }
        ],
        pageState: 'view',
        calculatedVisibilityPercentage: null,
        errorMessages: [],
        errorTitle: 'Error Adding Fragrance',
        isHeroImageAdded: false,
        isOneSheetPdfAdded: false,
        isLoading: false,
        isSaving: false,
        isDeleting: false,
        isHeroImageLoading: false,
        isOneSheetPdfLoading: false,
        isOneSheetPdfWithoutLogoLoading: false,
        isSavedFragranceExtendedLoading: false,
        showSaveSuccess: false,
        showSaveError: false,
        showInvalidForm: false,
        isDeleteModalVisible: false,
        currentName: null,
        currentId: null,
        isEnforceAdjectivesAndColors: false,
        isEnforceUploads: false
    }
  },
  created() {
    this.populateExtendedFragrance();
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
        publishedStateOptions: 'fragrancesExtendedModule/getPublishedStateOptions',
        collections: 'fragrancesExtendedModule/getCollections',
        families: 'fragrancesExtendedModule/getFamilies',
    }),  
    // extendedFragrance () {
    //     return this.$store.getters['fragrancesExtendedModule/getFragranceExtendedById'](this.$route.params.fragranceId);
    // },
    currentExtendedFragrance() {
        return this.$store.getters['fragrancesExtendedModule/getCurrentExtendedFragrance'];
    },
    uploadUrl () {
        return this.$store.getters['filesModule/getUploadUrl']; 
    },
    downloadUrl () {
        return this.$store.getters['filesModule/getDownloadUrl']; 
    },
    uploadedHeroImageUrl () {
        return this.$store.getters['fragrancesExtendedModule/getUploadedHeroImageUrl']; 
    },
    uploadedOneSheetPdfUrl () {
        return this.$store.getters['fragrancesExtendedModule/getUploadedOneSheetPdfUrl']; 
    },
    isFragranceExtendedExists () {
        var fExt = this.$store.getters['fragrancesExtendedModule/getFragranceExtendedById'](this.$route.params.fragranceId);
        var exists = fExt == null ? false : true;
        return exists;
    }
  },
  methods: {
    populateExtendedFragrance() {
        var extendedFragranceByRoute = this.$store.getters['fragrancesExtendedModule/getFragranceExtendedById'](this.$route.params.fragranceId);

        this.$store.commit('fragrancesExtendedModule/SET_CURRENT_FRAGANCE_EXTENDED', extendedFragranceByRoute);         


        this.isHeroImageAdded = this.currentExtendedFragrance.heroImageUrl != null && this.currentExtendedFragrance.heroImageUrl.length > 0 ? true : false;
        this.isOneSheetPdfAdded = this.currentExtendedFragrance.oneSheetPdfUrl != null && this.currentExtendedFragrance.oneSheetPdfUrl.length > 0 ? true : false;
        var newCrumb = { name: this.currentExtendedFragrance.name, url: '/fragrances/' + this.$route.params.fragranceId };
        this.crumbs.push(newCrumb);
        return;
    },
    async uploadHeroImage(e) {
        this.isHeroImageLoading = true;
        var file = e.target.files[0];
        var filename = e.target.files[0].name;
        this.isHeroImageAdded = true;
        let payload = new FormData();
            payload.append('file', file);
        var payloadObject = {
            filename: filename,
            file: payload
        }

        //this.$store.dispatch('fragrancesExtendedModule/uploadHeroImage', payloadObject);
        // await this.$store.dispatch('filesModule/uploadFile', payloadObject).then(() => {
        //     this.currentExtendedFragrance.heroImageUrl = this.downloadUrl;
        // }); 
        await uploadFile(payloadObject).then(downloadUrl => {
            this.currentExtendedFragrance.heroImageUrl = downloadUrl;
            this.isHeroImageLoading = false;
        });    
    },
    async uploadOneSheetPdf(e) {
        this.isOneSheetPdfLoading = true;
        var file = e.target.files[0];
        var filename = e.target.files[0].name;
        this.isOneSheetPdfAdded = true;
        let payload = new FormData();
            payload.append('file', file);
        var payloadObject = {
            filename: filename,
            file: payload
        }
        // await this.$store.dispatch('fragrancesExtendedModule/uploadOneSheetPdf', payloadObject).then(() => {
        //     this.currentExtendedFragrance.oneSheetPdfFilename = filename;
        //     this.currentExtendedFragrance.oneSheetPdfUrl = this.downloadOneSheetPdfUrl;
        // });

        // await this.$store.dispatch('filesModule/uploadFile', payloadObject).then(() => {
        //     this.currentExtendedFragrance.oneSheetPdfFilename = filename;
        //     this.currentExtendedFragrance.oneSheetPdfUrl = this.downloadUrl;
        // }); 
        await uploadFile(payloadObject).then(downloadUrl => {
            this.currentExtendedFragrance.oneSheetPdfUrl = downloadUrl;
            this.currentExtendedFragrance.oneSheetPdfFilename = filename;
            this.isOneSheetPdfLoading = false;
        }); 
        
    },
    async uploadOneSheetPdfWithoutLogo(e) {
        this.isOneSheetPdfWithoutLogoLoading = true;
        var file = e.target.files[0];
        var filename = e.target.files[0].name;
        this.isOneSheetPdfAdded = true;
        let payload = new FormData();
            payload.append('file', file);
        var payloadObject = {
            filename: filename,
            file: payload,
            container: "vse-file-upload-blank"
        }
        await uploadFile(payloadObject).then(downloadUrl => {
            this.currentExtendedFragrance.oneSheetPdfUrlWithoutLogo = downloadUrl;
            this.currentExtendedFragrance.oneSheetPdfWithoutLogoFilename = filename;
            this.isOneSheetPdfWithoutLogoLoading = false;
        }); 
    },
    getDownloadUrlFromFilename(filename) {
        console.log(filename);
    },
    showPageStateEdit() {
        this.pageState = 'edit';
        this.showSaveSuccess = false; 
    },
    showPageStateView() {
        this.pageState = 'view';
    },
    async validateAndSaveFragrance() {
        this.errorMessages = [];
        var isValid = true;
        // check for id
        if (this.currentExtendedFragrance.id == null || this.currentExtendedFragrance.id.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Id is required.');
        }
        // check for name
        if (this.currentExtendedFragrance.name == null || this.currentExtendedFragrance.name.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Name is required.');
        }
        // check for text in short, long description
        if (this.currentExtendedFragrance.shortDescription == null || this.currentExtendedFragrance.shortDescription.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Short Description is required.');
        }
        if (this.currentExtendedFragrance.longDescription == null || this.currentExtendedFragrance.longDescription.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Long Description is required.');
        }
        // check for selected family, collection
        if (this.currentExtendedFragrance.family == null || this.currentExtendedFragrance.family.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Family is required.');
        }
        if (this.currentExtendedFragrance.collection == null || this.currentExtendedFragrance.collection.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Collection is required.');
        }
        if (this.isEnforceUploads) {
            // check for hero image
            if (this.currentExtendedFragrance.heroImageUrl == null || this.currentExtendedFragrance.heroImageUrl.length == 0) {
                isValid = false;
                this.errorMessages.push('Fragrance Hero Image is required.');
            }
            // check for PDF
            if (this.currentExtendedFragrance.oneSheetPdfUrl == null || this.currentExtendedFragrance.oneSheetPdfUrl.length == 0) {
                isValid = false;
                this.errorMessages.push('Fragrance One Sheet PDF is required.');
            }
        }
        if (this.isEnforceAdjectivesAndColors) {
            // check for at least 3 colors
            if (this.currentExtendedFragrance.colors == null || this.currentExtendedFragrance.colors.length < 1) {
                isValid = false;
                this.errorMessages.push('At least 3 Fragrance Colors are required.');
            }
            // check for at least 3 adjectives with weight values
            if (this.currentExtendedFragrance.adjectives == null || this.currentExtendedFragrance.adjectives.length < 1) {
                isValid = false;
                this.errorMessages.push('At least one Fragrance Adjective is required.');
            }
        }
        if (this.currentExtendedFragrance.publishState == null || this.currentExtendedFragrance.publishState.length == 0) {
            isValid = false;
            this.errorMessages.push('Publish State is required.');
        }
        //console.log('isValid? ' + isValid + '; errors: ' + this.errorMessages.join(','));

        if (isValid == true) {
            this.isSaving = true;
            if (this.isFragranceExtendedExists) {
                // update
                await this.$store.dispatch('fragrancesExtendedModule/updateFragranceExtended', this.currentExtendedFragrance).then(() => {
                // add saved success message
                this.showSaveSuccess = true;
                this.isSaving = false;
                this.pageState = 'view';
                });
            } else {
                await this.$store.dispatch('fragrancesExtendedModule/addFragranceExtended', this.currentExtendedFragrance).then(() => {
                // add saved success message
                this.showSaveSuccess = true;
                this.isSaving = false;
                this.pageState = 'view';
                });
            }  
        } else {
            // show error message
            this.showInvalidForm = true;
        }
    },
    deleteFragranceWarning() {
        // show modal
        this.showSaveSuccess = false;
        this.isDeleteModalVisible = true;
    },
    async deleteFragrance() {
        let deletedFragranceName = this.currentExtendedFragrance.name;
        this.isDeleting = true;
        await this.$store.dispatch('fragrancesExtendedModule/deleteFragranceExtended', this.currentExtendedFragrance.id).then(() => {
            this.isDeleteModalVisible = false;
            this.isDeleting = false;
            this.$router.push('/fragrances/delete/' + deletedFragranceName);
        });
    },
    closeDeleteModal() {
        this.isDeleteModalVisible = false;
    },
    onCloseErrorMessageModal() {
        this.showInvalidForm = false;
        this.errorMessages = [];
    }
  }
}

</script>
