<template>
  <section id="UserLogs" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <div class="d-flex align-items-between">
      <div class="col">
        <h1 v-if="!showUserWiseLog">All Logs</h1>
        <h1 v-else>User Wise Logs</h1>
        <p>
          View user activity logs to track login attempts, changes, and system
          interactions.
        </p>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-4">
        <jumpNavigation></jumpNavigation>
      </div>
    </div>

    <!-- ACTIONS -->
    <div class="row my-1">
      <div class="col" v-if="!showUserWiseLog">
        <h2>Actions</h2>
        <div class="d-flex align-items-center">
          <div class="mr-3">
            <label for="fromDate" class="mr-2">From Date:</label>
            <input
              type="datetime-local"
              v-model="fromDate"
              id="fromDate"
              class="form-control"
            />
          </div>
          <div class="mr-3 p-3">
            <!-- Adjusted margin here -->
            <label for="toDate" class="mr-2">To Date:</label>
            <input
              type="datetime-local"
              v-model="toDate"
              id="toDate"
              class="form-control mr-4"
            />
          </div>
          <div>
            <button @click="allUserLogs" class="btn btn-primary mt-4">
              Refresh Logs
            </button>
          </div>
          <div class="mr-3 p-3">
            <button @click="clearDate" class="btn btn-secondary mt-4">
              Clear Date
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <button @click="goBack" class="btn btn-secondary mr-3 mt-1">
          Back To All Logs
        </button>
      </div>
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- User Logs Table -->
    <div v-else class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>User Name</th>
            <th>User Email</th>
            <th v-if="!showUserWiseLog">Total Count</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in userLogs" :key="log.user_id">
            <td>{{ getUserName(log.user_id) }}</td>
            <td>{{ getUserEmail(log.user_id) }}</td>
            <td v-if="!showUserWiseLog">
              <div
                @click="userWiseLogs(log.user_id)"
                class="link link-primary cursor-pointer"
              >
                {{ log.totalCount }}
              </div>
            </td>
            <td>
              {{ formatToAMPM(new Date(log.lastLoginTimestamp * 1000)) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <errorMessageModal
      v-if="showInvalidForm"
      @closeErrorMessageModal="onCloseErrorMessageModal"
      :errorTitle="errorTitle"
      :errorMessages="errorMessages"
    ></errorMessageModal>
  </section>
</template>

<script>
import axios from "axios";
import breadcrumbVue from "../../components/breadcrumb.vue";
import errorMessageModal from "../../components/error-message-modal.vue";

export default {
  name: "UserLogHistory",
  components: {
    breadcrumbVue,
    errorMessageModal,
  },
  data() {
    return {
      crumbs: [
        {
          name: "Dashboard",
          url: "/dashboard",
        },
        {
          name: "User Logs",
          url: "/user-logs",
        },
      ],
      isLoading: false,
      userLogs: [],
      ipAddress: null,
      usersCache: {},
      fromDate: "",
      toDate: "",
      showUserWiseLog: false,
      showInvalidForm: false,
      errorTitle: "Error Fetching User Logs",
      errorMessages: [],
    };
  },
  mounted() {
    this.allUserLogs();
  },
  methods: {
    async fetchUser(userId) {
      // If the user data is already cached, no need to fetch again
      if (!this.usersCache[userId]) {
        const userByRoute =
          this.$store.getters["usersModule/getUserById"](userId);
        this.usersCache[userId] = userByRoute || null;
      }
      return this.usersCache[userId];
    },
    getUserName(userId) {
      const user = this.usersCache[userId];
      return user ? `${user.firstName} ${user.lastName}` : userId;
    },
    getUserEmail(userId) {
      const user = this.usersCache[userId];
      return user ? user.email : "";
    },
    // async getIpAddress() {
    //   try {
    //     const response = await axios.get("https://api.ipify.org?format=json");
    //     this.ipAddress = response.data.ip;
    //     console.log("ipAddress ::", this.ipAddress);
    //   } catch (error) {
    //     console.error("Error fetching IP address:", error);
    //   }
    // },
    async allUserLogs() {
      this.isLoading = true;

      const params = {};
      if (this.fromDate && this.toDate) {
        params.fromDate = new Date(this.fromDate).toISOString();
        params.toDate = new Date(this.toDate).toISOString();
      }

      try {
        const response = await axios.get(
          "https://prolitecdataservices.azurewebsites.net/api/vseGetUserLogs",
          {
            params: params,
            headers: {
            'Content-Type': 'application/json',
            'x-functions-key': 'OVoArDVEY8XdPne865YUJi8apMRdY74DFND8T8VXlCDYII2LnTTi/w==',
            'Referrer-Policy': 'origin-when-cross-origin',
            }
          }
        );

        // Process the data after the response
        if (response && response?.data?.logs) {
          this.userLogs = response.data.logs.map((log) => ({
            ...log,
            localLastLoginTimestamp: new Date(log.lastLoginTimestamp * 1000),
          }));
          await Promise.all(
            this.userLogs.map(async (log) => {
              await this.fetchUser(log.user_id);
            })
          );
        } else {
          this.userLogs = [];
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
        this.errorMessages = [];
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.errorMessages.push(error.response.data.error);
          this.showInvalidForm = true;
        } else {
          this.errorMessages.push("An unknown error occurred.");
          this.showInvalidForm = true;
        }
        this.userLogs = [];
      } finally {
        this.isLoading = false;
      }
    },
    clearDate(){
      this.fromDate = "";
      this.toDate = "";
      this.allUserLogs();
    },
    async userWiseLogs(userId) {
      this.isLoading = true;

      const params = {};
      if (this.fromDate && this.toDate) {
        params.fromDate = new Date(this.fromDate).toISOString();
        params.toDate = new Date(this.toDate).toISOString();
      }

      try {
        const response = await axios.get(
          `https://prolitecdataservices.azurewebsites.net/api/vseGetUserLogs?userId=${userId}&isShowUserWiseLogs=true`,
          {
            params: params,
            headers: {
            'Content-Type': 'application/json',
            'x-functions-key': 'OVoArDVEY8XdPne865YUJi8apMRdY74DFND8T8VXlCDYII2LnTTi/w==',
            'Referrer-Policy': 'origin-when-cross-origin',
            }
          }
        );

        // Process the data after the response
        if (response && response?.data?.logs) {
          this.userLogs = response.data.logs.map((log) => ({
            ...log,
            localLastLoginTimestamp: new Date(log.lastLoginTimestamp * 1000), // Convert timestamp
          }));

          // Fetch users for all logs (caching happens in `fetchUser`)
          await Promise.all(
            this.userLogs.map(async (log) => {
              await this.fetchUser(log.user_id);
            })
          );
          this.showUserWiseLog = true;
        } else {
          this.userLogs = [];
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
        this.errorMessages = [];
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.errorMessages.push(error.response.data.error);
          this.showInvalidForm = true;
        } else {
          this.errorMessages.push("An unknown error occurred.");
          this.showInvalidForm = true;
        }
        this.userLogs = [];
      } finally {
        this.isLoading = false;
      }
    },
    formatToAMPM(date) {
      return new Date(date).toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true
      }).replace(",", "");
    },
    goBack() {
      this.$router.go(0);
    },
    onCloseErrorMessageModal() {
      this.showInvalidForm = false;
      this.errorMessages = [];
      this.$router.go(0);

    }
  }
};
</script>
