<template>    
  <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>   
  <ul class="navbar-nav justify-content-left align-items-center">
      <SignInOut></SignInOut>
        <li v-if="isUserSignedIn" class="inline-list-item me-3 nav-item">
            <router-link v-if="$route.matched.some(({ name }) => name != 'Dashboard')" to="/dashboard" class="link link-primary ms-2">Dashboard</router-link>
        </li>
         <li class="inline-list-item me-3 nav-item"><a href="mailto:vsesupport@prolitec.com">VSE Application Support</a></li>
   </ul>
   <ul class="navbar-nav justify-content-end align-items-center">  
      <li class="nav-item icon16">
        <a title="Change Theme" class="pointer" @click="selectTheme"><BIconCircleHalf /></a>
      </li>
      <li class="nav-item" v-if="isUserSignedIn">
          <!-- <select class="selectpicker ms-2" data-width="fit" onchange="translateLanguage(this.value);">
              <option data-content='<span class="fi fi-af"></span> Afrikaans' value="Afrikaans">Afrikaans</option>
              <option data-content='<span class="fi fi-al"></span> Albanian' value="Albanian">Albanian</option>
              <option data-content='<span class="fi fi-ar"></span> Arabic' value="Arabic">Arabic</option>
              <option data-content='<span class="fi fi-am"></span> Armenian' value="Armenian">Armenian</option>
              <option data-content='<span class="fi fi-az"></span> Azerbaijani' value="Azerbaijani">Azerbaijani</option>
          </select> -->
          <!-- <div id="google_translate_element" class="ms-2"></div> -->
          
          <!-- Google Translate Component -->
          <GoogleTranslate />
      </li>
      <!--   
      <li class="nav-item">
        <a class="nav-link link link-primary"
              v-if="!account"
              @click="SignIn"
              rel="noopener noreferrer"
            >
            Sign In
        </a>
        <a class="nav-link" v-else @click="SignOut" href="#" rel="noopener noreferrer">
          Sign Out <BIconBoxArrowRight />
        </a>
      </li>-->
    </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import SignInOut from './SignInOut.vue';
import GoogleTranslate from './GoogleTranslate.vue';
export default {
name: 'HeaderBar',
components: {
      SignInOut,
      GoogleTranslate,
  },
data() {
  return {
    signin: 'https://microsoft.com',
    userId: '',
    language: ''
  };
},
async created() {
  // this.$msalInstance = new PublicClientApplication(
  //   this.$store.state.msalConfig,
  // );
},
watch: {
  isUserSignedIn(newValue) {
    if (newValue) {
      //this.onUserSignedIn();
    }
  }
},
computed: {
      ...mapGetters({
          isUserSignedIn: 'isSignedInUser',
          account: 'getCurrentUser',
      }),
  },
methods: {
  selectTheme() {
      this.$store.commit('uiModule/TOGGLE_THEME');
  }
}
};
</script>
