<template>
  <section class="container col-md-3 col-md-offset-3 col-sm-6">
    <h1>The Virtual Scent Expert</h1>
    <div class="my-4">Welcome to Prolitec’s fragrance recommendation tool – 
        here to help you navigate the exciting, wide world of scent options. 
        Enter your email address and invite code to begin.</div>
    <div v-if="currentInvite != null">
        <form @submit.prevent="redeemInvite">
            <div class="row">
                <div class="col">
                    <label for="inputEmailAddress">Email Address</label>
                    <input type="email" required id="inputEmailAddress" class="form-control" v-model="currentInvite.email">
                </div>
            </div>
            <div class="row my-3">
                <div class="col">
                    <label for="inputCode">Invite Code</label>
                    <input type="text" id="inputCode" class="form-control" v-model="currentInvite.code" />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn btn-primary">Start Quiz</button>
                    <p v-if="this.isInviteNotFound == true" class="text-danger mt-3">We're sorry, we were unable to find your invitation.</p>
                    <p v-if="this.isValidForm == false" class="text-danger mt-3">Please fill out all fields.</p>
                </div>
            </div>
        </form>
    </div>
  </section>
</template>

<script>
import {mapGetters } from 'vuex';
import auth from "../services/auth";

export default {
    name: 'Invite',
    components: {
    },
    data() {
        return {
            currentInvite: {},
            isInviteLoading: false,
            isInviteNotFound: false,
            isValidForm: true
        }
    },
    mounted() {
        // if user is logged in, go to Start page
        if (this.account()) {
            this.$router.push('/start');
        }
        this.currentInvite = {
            email: '',
            code: '',
        };
        if (this.invites == null || this.invites.length < 1) {
            this.$store.dispatch('invitesModule/fetchInvites').then(()=> {
                if (this.quizResults == null || this.quizResults.length < 1) {
                    this.$store.dispatch('quizResultsModule/fetchQuizResults').then(()=> {
                    });
                }
            });
        }
    },
    computed: {
    ...mapGetters({
            invites: 'invitesModule/getInvites',
            quizResults: 'quizResultsModule/getQuizResults',
        }),
        account() {
            return auth.user;
        }
    },
    methods: {
        redeemInvite() {
            // reset invite found and isValidForm status...
            this.isValidForm = true;
            this.isInviteNotFound = false;
            // verify user + provider + code
            // get state of previous visit if unfinished
            // if unfinished, redirect to Quiz view, otherwise redirect to results page
            if (null != this.currentInvite && (null != this.currentInvite.email && this.currentInvite.email.length > 0) && (null != this.currentInvite.code && this.currentInvite.code.length == 6)) {
                this.isInviteLoading = true;
                // fetch invites every time
                this.$store.dispatch('invitesModule/fetchInvites').then(()=> {
                    // see if an invite matches
                    let existingInvite = this.invites.find(invite => invite.email == this.currentInvite.email && invite.code == this.currentInvite.code);
                    this.directUser(existingInvite);
                });
            } else {
                this.isValidForm = false;
            }
            this.isInviteLoading = false;
            
        },
        directUser(invite) {
            if (invite == null) {
                this.isInviteNotFound = true;
                return;
                // error message
            }
            // check for existing quiz result...
            var existingResults = this.quizResults.find(res => res.invite.code == invite.code && res.invite.email == invite.email);
            if (existingResults != null) {
                if (existingResults.id.length > 8 && existingResults.isQuizComplete == true) {
                    this.$router.push('/results/' + existingResults.id);
                    return;
                }

                if (existingResults.id.length > 8 && existingResults.isQuizStarted == true) {
                    this.$router.push('/quiz/' + existingResults.id);
                    return;
                }
            }
            
            // update with responsedate, forward to quiz
            invite.responseDate = new Date();
            let newQuizResult = {
                isQuizStarted: true,
                isQuizCompleted: false,
                permalinkUrl: null,
                quizStartDate: new Date(),
                quizCompleteDate: null,
                invite: invite,
                project: {},
                questions: [],
                recommendedFragrances: [],
                adminRecommendedFragrancesCsvUrl: null,
                adminSelectedAnswersCsvUrl: null
            }
            this.$store.dispatch('invitesModule/updateInvite', invite).then(()=> {
                this.$store.dispatch('quizResultsModule/addQuizResult', newQuizResult).then((response) => {
                // get new QuizResult id and redirect
                let newQuizResultId = response.id;
                this.$router.push('/quiz/' + newQuizResultId);
                });
                
            });
        }
    }
}
</script>
