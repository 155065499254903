<template>
  <section id="ProviderDetail" class="container">
    <div class="row mt-4">
      <breadcrumbVue :items="crumbs"></breadcrumbVue>
    </div>
    <div v-if="pageState != 'view'">
      <nav
        class="navbar navbar-edit navbar-expand fixed-top justify-content-between"
      >
        <div class="container">
          <span>Edit Provider: {{ currentProvider.name }}</span>
          <span>
            <button @click="showPageStateView" class="btn btn-secondary me-3">
              Cancel
            </button>
            <button @click="validateAndSaveProvider" class="btn btn-primary">
              <span>Save</span>
              <span
                v-show="isSaving"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </span>
        </div>
      </nav>
    </div>
    <!-- <div v-else>
          <h1 style="margin-top:-.3em;">{{ currentProvider.name }}</h1>
      </div> -->
    <div
      v-else
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <h1 style="margin-top: -0.3em">{{ currentProvider.name }}</h1>
      <div>
        <button class="btn btn-primary me-4" @click="openUploadModal()">
          Upload Logo
        </button>
        <button class="btn btn-info" @click="fetchImages(currentProvider.name)">
          View Gallery
        </button>
      </div>
    </div>
    <!--Upload Modal-->
    <div
      class="modal fade"
      id="imageUploadModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="imageUploadModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="imageUploadModalLabel">
              Upload Image
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <!-- Image Preview with Delete Button -->
            <div v-if="imagePreview" class="position-relative mt-3">
              <img
                :src="imagePreview"
                class="w-100 rounded"
                style="max-height: 200px; object-fit: cover"
              />
              <button
                class="position-absolute top-0 end-0 m-1 border-0 bg-danger text-white d-flex align-items-center justify-content-center"
                @click="removeImage"
                style="
                  width: 20px;
                  height: 20px;
                  font-size: 12px;
                  border-radius: 4px;
                  cursor: pointer;
                "
              >
                ✖
              </button>
            </div>

            <!-- File Upload Section -->
            <div class="mt-3">
              <input
                :value="uploadedFileName"
                type="file"
                class="form-control"
                @change="onFileChange"
                accept=".jpg,.jpeg,.png"
                ref="fileInput"
              />
              <span v-if="selectedFileName" class="mt-2 d-block">{{
                selectedFileName
              }}</span>
              <small class="text-muted d-block mt-2">
                <i style="font-weight: bold;">Please upload an image in JPG, JPEG, or PNG format. The image should not exceed a width of 180 pixels and a height of 60 pixels.</i>
              </small>
            </div>
          </div>
          <div v-if="isFileLoading" class="d-flex justify-content-center mt-3">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              class="btn btn-primary"
              @click="uploadFile"
              :disabled="!selectedFile"
            >
              Upload
              <span
                v-show="isFileLoading"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- View Modal -->
    <div
      class="modal fade"
      id="galleryModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="galleryModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="galleryModalLabel">Select an Image</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              v-if="isFileLoading"
              class="d-flex justify-content-center mt-3"
            >
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <!-- Scrollable Gallery -->
            <div v-if="!isFileLoading && FetchedImages.length === 0" class="text-center">
              <p>No image preview available</p>
            </div>
            <div class="image-gallery-container">
              <div
                v-for="(image, index) in FetchedImages"
                :key="index"
                class="image-item"
              >
                <div class="position-relative">
                  <!-- Radio Button for Selection -->
                  <input
                    type="radio"
                    :id="'image-' + index"
                    v-model="selectedImage"
                    :value="image.url"
                    class="position-absolute top-0 start-0 m-0"
                    />
                    <!-- style="z-index: 2" -->
                  <!-- Image -->
                  <img
                    :src="image.url"
                    :alt="`Image ${index}`"
                    class="gallery-img mt-3 ms-2"
                    @click="selectImage(image.url)"
                  />
                  <!-- Optionally, you can show a border or highlight the selected image -->
                  <div
                    v-if="selectedImage === image.url"
                    class="position-absolute top-0 start-0 w-100 h-100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between w-100">
            <button
              type="button"
              class="btn btn-danger"
              @click="openDeleteImageModal"
              :disabled="!selectedImage"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showSaveSuccess == true">
      <div class="alert fade show alert-success" role="alert">
        Your changes were saved.
      </div>
    </div>
    <div class="row my-3 p-3">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="showLogoOnPdf" v-model="currentProvider.showLogoOnPdf" :disabled="pageState == 'view'">
                    <label class="form-check-label" for="showLogoOnPdf">
                      Display Logo On Pdf
                    </label>
                </div>
            </div>
    <!-- FRAGRANCES -->
    <div class="row my-5">
      <h2>Fragrance Inventory</h2>
      <div v-if="pageState == 'edit'">
        <a class="link link-primary" @click="selectAllFragrances">Select All</a>
        |
        <a class="link link-primary" @click="deselectAllFragrances"
          >Deselect All</a
        >
      </div>
      <ul class="list-unstyled card-columns">
        <li
          v-for="fragrance in allFragrances"
          :key="fragrance.id"
          class="p-2 mb-2"
        >
          <div class="form-check form-switch">
            <!-- Checkbox -->
            <input
              class="form-check-input"
              :id="'flexSwitchCheckDefault_' + fragrance.id"
              type="checkbox"
              role="switch"
              @change="toggleFragranceSelection(fragrance.id)"
              :checked="isFragranceSelected(fragrance.id)"
              :disabled="pageState == 'view'"
            />
            <!-- Name of Fragrance -->
            <label
              class="form-check-label notranslate"
              :for="'flexSwitchCheckDefault_' + fragrance.id"
              >{{ fragrance.name }}</label
            >
          </div>
        </li>
      </ul>
    </div>
    <!-- END FRAGRANCES -->

    <!-- ACTIONS -->
    <div class="row my-5">
      <div class="col">
        <h2>Actions</h2>
        <button @click="showPageStateEdit" class="btn btn-primary">Edit</button>
      </div>
    </div>

    <!-- DELETE -->
    <div class="row my-5">
      <div class="col">
        <h2>Danger Zone</h2>
        <button @click="deleteProviderWarning" class="btn btn-danger">
          Delete
        </button>
      </div>
    </div>

    <!-- DELETE MODAL -->
    <div
      v-if="isDeleteModalVisible"
      class="modal fade show"
      id="myModal"
      style="display: block"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Delete Provider</h4>
            <button
              type="button"
              class="btn-close"
              @click="closeDeleteModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span class="visually-hidden" aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p id="FragranceDeleteText">
              Are you sure you want to delete
              <strong>{{ currentProvider.name }}</strong
              >?
            </p>
            <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeDeleteModal"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteFragrance"
              data-dismiss="modal"
            >
              <span>Confirm</span>
              <span
                v-show="isDeleting"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDeleteModalVisible" class="modal-backdrop fade show"></div>
    <!-- END: DELETE MODAL -->
     <!-- DELETE IMAGE CONFIRMATION MODAL -->
    <div
      v-if="isDeleteImageModalVisible"
      class="modal fade show"
      id="deleteImageModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      style="display: block"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Delete Image</h4>
            <button
              type="button"
              class="btn-close"
              @click="closeDeleteImageModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete this image?</p>
          </div>
          <div class="modal-footer d-flex justify-content-between w-100">
            <button type="button" class="btn btn-danger" @click="deleteImage">
              <span>Confirm</span>
              <span
                v-show="isDeleting"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button type="button" class="btn btn-secondary" @click="closeDeleteImageModal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDeleteImageModalVisible" class="modal-backdrop fade show"></div>

    <errorMessageModal
      v-if="showInvalidForm"
      @closeErrorMessageModal="onCloseErrorMessageModal"
      :errorTitle="errorTitle"
      :errorMessages="errorMessages"
    ></errorMessageModal>
  </section>
</template>

<script>
import breadcrumbVue from "../../components/breadcrumb.vue";
import errorMessageModal from "../../components/error-message-modal.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import { Modal } from "bootstrap";
import { toast } from 'vue3-toastify';
//const toast = useToast();
// import "vue-toastification/dist/index.css";

export default {
  name: "ProviderDetail",
  components: {
    breadcrumbVue,
    errorMessageModal,
  },
  data() {
    return {
      crumbs: [
        {
          name: "Dashboard",
          url: "/dashboard",
        },
        {
          name: "Providers",
          url: "/providers",
        },
      ],
      pageState: "view",
      errorMessages: [],
      errorTitle: "Error Adding Provider",
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      showSaveSuccess: false,
      showSaveError: false,
      showInvalidForm: false,
      isDeleteModalVisible: false,
      currentProvider: {
        fragrances: [],
        showLogoOnPdf: false
      },
      currentName: null,
      currentId: null,
      isEnforceUploads: false,
      selectedFile: null,
      selectedFileName: "",
      uploadedFileName:null,
      imagePreview: null,
      isFileLoading: false,
      images: [],
      FetchedImages: [],
      selectedImage: null,
      viewModal: null,
      finalSelectedImage: "",
      uploadModal: null,
      isDeleteImageModalVisible: false,
      isImageDeleting: false,
      showLogoCheckBox: false
    };
  },
  created() {
    this.populateProvider();
  },
  mounted() {
    // this.toast = useToast();
  },
  computed: {
    ...mapGetters({
      allFragrances: "fragrancesExtendedModule/getFragrancesExtended",
      getCurrentUserInRole: "getCurrentUserInRole",
    }),
  },
  methods: {
    populateProvider() {
      this.currentProvider = this.$store.getters[
        "providersModule/getProviderById"
      ](this.$route.params.providerId);
      if (this.currentProvider.fragrances == null) {
        this.currentProvider.fragrances = [];
      }

      var newCrumb = {
        name: this.currentProvider.name,
        url: "/providers/" + this.$route.params.providerId,
      };
      this.crumbs.push(newCrumb);
      return;
    },
    selectAllFragrances() {
      this.currentProvider.fragrances = this.allFragrances.map(
        (allFragrances) => ({
          id: allFragrances.id,
        })
      );
    },
    deselectAllFragrances() {
      this.currentProvider.fragrances = [];
    },
    isFragranceSelected(fragranceId) {
      if (
        this.currentProvider.fragrances != null &&
        this.currentProvider.fragrances.length > 0
      ) {
        let index = this.currentProvider.fragrances.findIndex(
          (f) => f.id == fragranceId
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    toggleFragranceSelection(fragranceId) {
      let index = this.currentProvider.fragrances.findIndex(
        (f) => f.id == fragranceId
      );
      if (index > -1) {
        // it's found - remove it
        this.currentProvider.fragrances.splice(index, 1);
      } else {
        // add the fragrance
        this.currentProvider.fragrances.push({ id: fragranceId });
      }
    },
    showPageStateEdit() {
      this.pageState = "edit";
      this.showSaveSuccess = false;
    },
    showPageStateView() {
      this.pageState = "view";
    },
    async validateAndSaveProvider() {
      this.errorMessages = [];
      var isValid = true;
      // check for id
      if (
        this.currentProvider.id == null ||
        this.currentProvider.id.length == 0
      ) {
        isValid = false;
        this.errorMessages.push("Fragrance Id is required.");
      }
      // check for name
      if (
        this.currentProvider.name == null ||
        this.currentProvider.name.length == 0
      ) {
        isValid = false;
        this.errorMessages.push("Fragrance Name is required.");
      }

      if (
        this.currentProvider.fragrances == null ||
        this.currentProvider.fragrances.length < 1
      ) {
        isValid = false;
        this.errorMessages.push("At least 1 Fragrances is required.");
      }

      if (isValid == true) {
        this.isSaving = true;
        // update
        await this.$store
          .dispatch("providersModule/updateProvider", this.currentProvider)
          .then(() => {
            // add saved success message
            this.showSaveSuccess = true;
            this.isSaving = false;
            this.pageState = "view";
          });
      } else {
        // show error message
        this.showInvalidForm = true;
      }
    },
    deleteProviderWarning() {
      // show modal
      this.showSaveSuccess = false;
      this.isDeleteModalVisible = true;
    },
    async deleteProvider() {
      let deletedProviderName = this.currentProvider.name;
      this.isDeleting = true;
      await this.$store
        .dispatch("providersModule/deleteProvider", this.currentProvider.id)
        .then(() => {
          this.isDeleteModalVisible = false;
          this.isDeleting = false;
          this.$router.push("/providers/delete/" + deletedProviderName);
        });
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
    },
    onCloseErrorMessageModal() {
      this.showInvalidForm = false;
      this.errorMessages = [];
    },
    onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          // Validate file extension
          const validExtensions = ['.jpg', '.jpeg', '.png'];
          const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
          if (!validExtensions.includes(`.${fileExtension}`)) {
           // alert("Invalid file type. Please upload an image in JPG, JPEG, or PNG format.");
            toast.error("Invalid file type. Please upload an image in JPG, JPEG, or PNG format.");
            this.selectedFile = null;
            this.selectedFileName = null;
            this.imagePreview = null;
            this.uploadModal.hide();
            return;
          }

          this.selectedFile = file;
          this.selectedFileName = file.name;

          if (file.type.startsWith("image/")) {
            this.imagePreview = URL.createObjectURL(file);
          } else {
            this.imagePreview = null;
          }
          this.uploadedFileName = null;
        }
      },
    removeImage() {
      this.selectedFile = null;
      this.selectedFileName = "";
      this.imagePreview = null;
    },
    async uploadFile() {
      if (!this.selectedFile) {
        alert("Please select a file first.");
        return;
      }
      this.isFileLoading = true;
      const fileName = this.selectedFile.name;
      const folderName = this.currentProvider.name;
      this.isFileLoading = true;
      const formData = new FormData();
      formData.append("file", this.selectedFile);

      try {
        const uploadUrl = `https://prolitecdataservices.azurewebsites.net/api/vseUploadUserLogo?filename=${encodeURIComponent(fileName)}&Provider=${folderName}`;
        await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': 'ZNzzlkKfzVMg337P4RE59TB7yQJ3D4ETvX1LwrH2c6xqAzFuNtHFzg==',
            'Referrer-Policy': 'origin-when-cross-origin',
          },
        }).then((res) => {
        toast.success(res?.data?.message);
        this.$refs.fileInput.value = "";
        this.uploadModal.hide();
        this.removeImage();
      }, (err) => {
        toast.error(err?.response?.data?.message);
        this.$refs.fileInput.value = "";
        this.uploadModal.hide();
        this.removeImage();
        })
      } catch (error) {
        console.error("Upload Error:", error);
        toast.error(error);
      } finally {
        this.isFileLoading = false;
      }
    },
    openUploadModal(){
      this.uploadModal = new Modal(document.getElementById("imageUploadModal"));
      this.uploadModal.show();
    },
    async fetchImages(provider) {
      this.viewModal = new Modal(document.getElementById("galleryModal"));
      this.isFileLoading = true;
      this.viewModal.show();
      try {
        const response = await axios.get(
          `https://prolitecdataservices.azurewebsites.net/api/vseGetUserLogo?Provider=${provider}`, {
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': 'aT9mFFAfdQyUECvcY4Td2MOqDzZGkCkCLfd87hh1NheIAzFuco4XCQ==',
            'Referrer-Policy': 'origin-when-cross-origin',
          }
          });
        this.FetchedImages = response.data.images;
        this.isFileLoading = false;
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    },
    selectImage(image) {
      this.selectedImage = image;
    },
    confirmSelection() {
      if (this.selectedImage) {
        this.viewModal.hide();
        this.finalSelectedImage = this.selectedImage;
      } else {
        alert("Please select an image!");
      }
    },
    openDeleteImageModal() {
      this.isDeleteImageModalVisible = true;
    },
    closeDeleteImageModal() {
      this.isDeleteImageModalVisible = false;
      this.selectedImage = null;
    },
    async deleteImage() {
      if (!this.selectedImage) {
        alert("Please select an image to delete.");
        return;
      }
      this.isImageDeleting = true;
      this.isLoading = true;
      const decodedImage = decodeURIComponent(this.selectedImage);
      const imageFile = decodedImage.split("/").pop().split("?")[0];
      const payload = {
        image: imageFile,
        folder: this.currentProvider.name,
      };
      try {
        await axios.delete("https://prolitecdataservices.azurewebsites.net/api/vseDeleteProviderLogo", {
          data: payload,
          headers: {
            'Content-Type': 'application/json',
            'x-functions-key': 'oI1AKh_nFS-ZXEtTKkYzafldTSq31VZcoAyW5hXIHxE1AzFuFjDLrw==',
            'Referrer-Policy': 'origin-when-cross-origin',
          },
        }).then((res) => {
          this.FetchedImages = this.FetchedImages.filter(
          (image) => image.url !== this.selectedImage
        );
        toast.success(res?.data?.message);
        this.closeDeleteImageModal();
        this.viewModal.hide();
      }, (err) => {
        toast.success(err?.data?.message);
        this.closeDeleteImageModal();
        this.viewModal.hide();
        });
        this.FetchedImages = this.FetchedImages.filter(
          (image) => image.url !== this.selectedImage
        );
      } catch (error) {
        console.error("Error deleting image:", error);
        toast.error("Failed to delete image. Please try again.");
      }finally {
        this.isImageDeleting = false;
      }
    },
  },
};
</script>
<style scoped>
/* Scrollable Gallery Container */
.image-gallery-container {
  display: flex; /* Use flex layout */
  flex-direction: row; /* Arrange images in a row */
  flex-wrap: wrap; /* Allow images to wrap to the next line */
  gap: 30px; /* Space between images */
  padding: 10px; /* Padding around the container */
  max-height: 200px; /* Set max height for the container to trigger scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

/* Styling for each image */
.image-item {
  width: 150px; /* Set a fixed width for each image item */
  height: auto; /* Maintain aspect ratio */
  display: flex; /* Use flex for positioning the content inside each item */
  position: relative; /* Needed for absolute positioning of the radio button */
  flex-shrink: 0; /* Prevent shrinking of the image */
}

/* Image Styling */
.gallery-img {
  width: 100%; /* Make the image fill the container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the space without distortion */
  border-radius: 8px; /* Optional: rounded corners */
  cursor: pointer; /* Change cursor to indicate clickable image */
  transition: transform 0.2s ease-in-out; /* Smooth transition on hover */
}

/* Hover Effect (Optional) */
.gallery-img:hover {
  transform: scale(1.1); /* Slightly enlarge the image on hover */
}

/* Positioning for the radio button */
input[type="radio"] {
  z-index: 2; /* Make sure the radio button is on top of the image */
  cursor: pointer;
  position: absolute; /* Position the radio button on top of the image */
  top: 10px;
  left: 10px;
}
</style>
