<template>
    <section>
        <adjectiveAdminSelectedList @removeItem="onItemRemoved" :enableWeight="false" :selectedAdjectives="selectedAdjectives"></adjectiveAdminSelectedList>
        <adjectiveAdminList @addItem="onItemAdded" :selectedAdjectives="this.selectedAdjectives"></adjectiveAdminList>
    </section>
</template>
<script>
import adjectiveAdminSelectedList from './adjective-admin-list-selected.vue';
import adjectiveAdminList from './adjective-admin-list.vue';

export default {
  name: 'AnswerAdjectiveSelect',
  components: {
    adjectiveAdminSelectedList,
    adjectiveAdminList,
  },
  data() {
    return {
        activeAdjectiveIndex: 0
    }
  },
  props: {
    selectedAdjectives: {
        type: Array
    }
  },
  methods: {
    onItemAdded(newAdjective) {
        //this.selectedAdjectives.push(newAdjective);
        this.$emit('addAdjective', newAdjective);

    },
    onItemRemoved(adjectiveId) {
        this.$emit('removeAdjective', adjectiveId);
    }
  }
};
</script>