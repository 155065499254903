<template>
  <section v-if="getCurrentUserInRole('Task.Admin') | getCurrentUserInRole('Task.Manager')" id="ProviderList" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <div class="d-flex align-items-between">
        <div class="col">
            <h1>Providers</h1>
            <p>To view or edit provider a provider, click their name or the pencil icon. 
                To delete a provider, click their name, the pencil icon, or the trash icon. 
                To add a provider, click the “add” button.</p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4">
            <jumpNavigation></jumpNavigation>
        </div>
    </div>
    <div>
      <router-link to="/providers/add" class="btn btn-primary">Add Provider</router-link>
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Provider List -->
    <div v-else class="table-responsive">
        <table class="table table-striped">
        <thead>
            <tr>
            <th>Name</th>
            <th class="text-end">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="provider in providers" :key="provider.id">
                <td>
                    <router-link v-if="provider.id != null" :to="{ name: 'ProviderDetail', params: { providerId: provider.id }}" class="link link-primary">{{ provider.name }}</router-link>
                </td>
                <td class="text-end text-primary">
                    <a @click="showDeleteModal(provider.id)"><BIconTrashFill variant="danger" /></a>
                </td>
            </tr>
        </tbody>
        </table>
    </div>
    <!-- UPDATE PROVIDER MODAL -->
    <div v-if="isEditModalVisible" class="modal fade show" id="myModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Edit Provider</h4>
                    <button type="button" class="btn-close" @click="closeEditModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label for="inputEditName" class="form-label">Name</label>
                    <input id="inputEditName" v-model="updatedProviderText" type="text" class="form-control" placeholder="Name" aria-label="Name">
                    <!--<p id="ProviderEditId">{{ this.selectedProvider != null && this.selectedProvider.id != null ? this.selectedProvider.id : '' }}</p>-->
                    <ul v-if="showInvalidForm == true">
                        <li v-for="error in errorMessages" v-bind:key="error" class="text-danger">{{ error }}</li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" @click="saveUpdatedProvider" data-dismiss="modal">
                        <span>Save</span>
                        <span v-show="isProviderUpdating" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-danger" @click="deleteSelectedProvider" data-dismiss="modal">
                        <span>Delete</span>
                        <span v-show="isProviderDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="closeEditModal" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isEditModalVisible" class="modal-backdrop fade show"></div>

    <!-- DELETE PROVIDER MODAL -->
    <div v-if="isDeleteModalVisible" class="modal fade show" id="myModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Delete Provider</h4>
                    <button type="button" class="btn-close" @click="closeDeleteModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p id="FragranceDeleteText">Are you sure you want to delete <strong>{{ selectedProvider.name }}</strong>?</p>
                    <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeDeleteModal" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="deleteSelectedProvider" data-dismiss="modal">
                        <span>Confirm</span>
                        <span v-show="isProviderDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isDeleteModalVisible" class="modal-backdrop fade show"></div>
  </section>
</template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import jumpNavigation from '../../components/jump-navigation.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProviderList',
  components: {
    breadcrumbVue,
    jumpNavigation,
  },
  data() {
    return {
      crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Providers',
                url: '/providers'
      }],
      payload:  '',
      currentSort: '',
      currentSortDir: 'asc',
      updatedProviderText: '',
      selectedProvider: null,
      isEditModalVisible: false,
      isDeleteModalVisible: false,
      isLoading: false,
      isProviderUpdating: false,
      isProviderDeleting: false,
      errorMessages: [],
      showInvalidForm: false
    }
  },
  provide() {
    return {
    }
  },
  mounted() {
    // get from API
    if (this.$store.getters.getProviders == null) {
        this.load();
    }
  },
  computed: {
    ...mapGetters({
        providers: 'providersModule/getProviders',
        getCurrentUserInRole: 'getCurrentUserInRole',
        count: 'providersModule/count'
    })
  },
  methods: {
    //...mapActions('')
    async load() {
        this.isLoading = true;
        await this.$store.dispatch("providersModule/fetchProviders").then(() => {
            this.isLoading = false;
        });                  
    },
    showEditModal(providerId) {
        // get adjective by ID
        this.errorMessages = [];
        this.selectedProvider = this.providers.find(item => item.id == providerId);
        this.updatedProviderText = this.selectedProvider.name;
        this.isEditModalVisible = true;
    },
    closeEditModal() {
        this.isEditModalVisible = false;
        this.selectedProvider = null;
    },
    showDeleteModal(providerId) {
        this.selectedProvider = this.providers.find(item => item.id == providerId);
        this.isDeleteModalVisible = true;
    },
    closeDeleteModal() {
        this.selectedProvider = null;
        this.isDeleteModalVisible = false;
    },
    async saveUpdatedProvider() {
        this.errorMessages = [];
        var isValid = true;

        // check for text in adjective
        if (this.updatedProviderText == null || this.updatedProviderText.length < 1) {
            isValid = false;
            this.errorMessages.push('Provider is required.');
        }

        if (isValid) {
            this.isProviderUpdating = true;
            this.selectedProvider.name = this.updatedProviderText;
            await this.$store.dispatch("providersModule/updateProvider", this.selectedProvider).then(() => {
                this.isProviderUpdating = false;
                this.closeEditModal();
            }); 
        } else {
            this.showInvalidForm = true;
        }     
    },
    async deleteSelectedProvider() {
        this.isProviderDeleting = true;
        await this.$store.dispatch("providersModule/deleteProvider", this.selectedProvider.id).then(() => {
            this.isProviderDeleting = false;
            this.closeDeleteModal();
            this.closeEditModal();
        });
    }
  }
};
</script>
