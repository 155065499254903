<template>
  <section id="UserView" class="container">
    <div class="row mt-4">
      <breadcrumbVue :items="crumbs"></breadcrumbVue>
    </div>
    <div v-if="pageState != 'view'">
      <nav
        class="navbar navbar-edit navbar-expand fixed-top justify-content-between"
      >
        <div class="container">
          <span
            >Edit User:
            {{ currentUser.firstName + " " + currentUser.lastName }}</span
          >
          <span>
            <button @click="showPageStateView" class="btn btn-secondary me-3">
              Cancel
            </button>
            <button @click="validateAndSaveUser" class="btn btn-primary">
              <span>Save</span>
              <span
                v-show="isUserSaving"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </span>
        </div>
      </nav>
    </div>
    <div v-else>
      <h1 style="margin-top: -0.3em">
        {{ currentUser.firstName + " " + currentUser.lastName }}
      </h1>
    </div>

    <div class="row">
      <div class="col">
        <label for="inputFirstName" class="form-label">First Name</label>
        <input
          id="inputFirstName"
          v-model="currentUser.firstName"
          type="text"
          class="form-control"
          placeholder="First name"
          aria-label="First name"
          :disabled="pageState == 'view'"
        />
      </div>
      <div class="col">
        <label for="inputLastName" class="form-label">Last Name</label>
        <input
          id="inputLastName"
          v-model="currentUser.lastName"
          type="text"
          class="form-control"
          placeholder="Last name"
          aria-label="Last name"
          :disabled="pageState == 'view'"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="inputEmail" class="form-label">Email</label>
        <input
          id="inputEmail"
          v-model="currentUser.email"
          type="email"
          class="form-control"
          placeholder="Email"
          aria-label="Email"
          :disabled="pageState == 'view' || isProlitecUser"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="selectProvider" class="form-label">Scent Provider</label>
        <select
          id="selectProvider"
          v-model="currentUser.provider"
          class="form-select"
          aria-label="Select Provider"
          :disabled="enableProviderByRole()"
        >
          <option value="0" selected>Select a role...</option>
          <option
            v-for="provider in providers"
            v-bind:key="provider.id"
            :value="provider.id"
          >
            {{ provider.name }}
          </option>
        </select>
      </div>
      <div class="col">
        <label for="selectRole" class="form-label">Role</label>
        <select
          id="selectRole"
          v-model="currentUser.role"
          class="form-select"
          aria-label="Select role"
          :disabled="pageState == 'view'"
        >
          <option value="0" selected>Select a role...</option>
          <option v-bind:value="groupConstants.user">Salesperson</option>
          <option
            v-if="canView(groupConstants.manager)"
            v-bind:value="groupConstants.manager"
          >
            Manager
          </option>
          <option
            v-if="canView(groupConstants.admin)"
            v-bind:value="groupConstants.admin"
          >
            Admin
          </option>
        </select>
      </div>
    </div>
    <br />
    <div class="d-flex align-items-center" v-if="showChooseLogo">
      <div>
        <label for="selectLogo" class="form-label me-3">{{
          pageState == "edit" ? "Choose Logo" : "Current Logo"
        }}</label>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          @click="fetchImages(getProviderName(currentUser.provider))"
          v-if="pageState == 'edit'"
        >
          Open Gallery
        </button>
      </div>
      <div v-if="isLoading" class="d-flex justify-content-center mt-3">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-if="!isLoading && finalSelectedImage" class="image-container">
        <div class="h-[2rem] w-[2rem]">
          <img
            :src="finalSelectedImage"
            alt="'Image not found'"
            class="w-100 shadow-1-strong rounded mb-2 gallery-img"
          />
          <button v-if="showCrossSign" class="cross-btn" @click="removeImage">
            ×
          </button>
        </div>
      </div>
    </div>

    <!-- Gallery Modal -->
    <div
      class="modal fade"
      id="galleryModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="galleryModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="galleryModalLabel">Select an Image</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="max-height: 60vh; overflow-y: auto">
            <div v-if="isLoading" class="d-flex justify-content-center mt-3">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <!-- Gallery -->
            <div v-if="images.length === 0" class="text-center">
              <p>No image preview available</p>
            </div>
            <div v-else class="image-gallery-container">
              <div
                v-for="(image, index) in images"
                :key="index"
                class="image-item"
              >
                <div class="position-relative">
                  <!-- Radio Button for Selection -->
                  <input
                    type="radio"
                    :id="'image-' + index"
                    v-model="selectedImage"
                    :value="image.url"
                    class="position-absolute top-0 start-0 m-0"
                    style="z-index: 2"
                  />
                  <!-- Image -->
                  <img
                    :src="image.url"
                    :alt="`Image ${index}`"
                    class="gallery-img"
                    @click="selectImage(image.url)"
                  />
                  <!-- Optionally, you can show a border or highlight the selected image -->
                  <div
                    v-if="selectedImage === image.url"
                    class="position-absolute top-0 start-0 w-100 h-100"
                  ></div>
                </div>
              </div>
            </div>
            <!-- Gallery -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="confirmSelection"
            >
              Select Image
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ACTIONS -->
    <div class="row my-5" v-if="canEditUser()">
      <div class="col">
        <h2>Actions</h2>
        <button @click="showPageStateEdit" class="btn btn-primary">Edit</button>
      </div>
    </div>

    <!-- DELETE -->
    <div class="row my-5" v-if="canEditUser()">
      <div class="col">
        <h2>Danger Zone</h2>
        <button @click="deleteUserWarning" class="btn btn-danger">
          Delete
        </button>
      </div>
    </div>

    <!-- DELETE MODAL -->
    <div
      v-if="isDeleteModalVisible"
      class="modal fade show"
      id="myModal"
      style="display: block"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Delete User</h4>
            <button
              type="button"
              class="btn-close"
              @click="closeDeleteModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span class="visually-hidden" aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p id="UserDeleteText">
              Are you sure you want to delete
              <strong>{{
                this.currentUser.firstName + " " + this.currentUser.lastName
              }}</strong
              >?
            </p>
            <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeDeleteModal"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteUser"
              data-dismiss="modal"
            >
              <span>Confirm</span>
              <span
                v-show="isDeleting"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDeleteModalVisible" class="modal-backdrop fade show"></div>
    <errorMessageModal
      v-if="showInvalidForm"
      @closeErrorMessageModal="onCloseErrorMessageModal"
      :errorTitle="errorTitle"
      :errorMessages="errorMessages"
    ></errorMessageModal>
  </section>
</template>

<script>
import breadcrumbVue from "../../components/breadcrumb.vue";
import errorMessageModal from "../../components/error-message-modal.vue";
import { mapGetters } from "vuex";
import {
  VUE_APP_VSE_USER_GROUP_ID,
  VUE_APP_VSE_MANAGER_GROUP_ID,
  VUE_APP_VSE_ADMIN_GROUP_ID,
} from "../../utils/constants.js";
import axios from "axios";
import { Modal } from "bootstrap";
export default {
  name: "UserDetail",
  components: {
    breadcrumbVue,
    errorMessageModal,
  },
  data() {
    return {
      crumbs: [
        {
          name: "Dashboard",
          url: "/dashboard",
        },
        {
          name: "Users",
          url: "/users",
        },
      ],
      groupConstants: {
        user: VUE_APP_VSE_USER_GROUP_ID,
        manager: VUE_APP_VSE_MANAGER_GROUP_ID,
        admin: VUE_APP_VSE_ADMIN_GROUP_ID,
      },
      currentUser: {},
      pageState: "view",
      isLoading: false,
      isSavedUserLoading: false,
      isUserSaving: false,
      isDeleting: false,
      showSaveSuccess: false,
      showSaveError: false,
      isDeleteModalVisible: false,
      currentName: null,
      currentId: null,
      errorMessages: [],
      errorTitle: "Error Saving User",
      showInvalidForm: false,
      showChooseLogo: true,
      finalSelectedImage: "",
      images: [],
      selectedImage: "",
      modal: null,
      showCrossSign: false,
    };
  },
  created() {
    this.populateUser();
  },
  mounted() {
    this.onProviderSelected();
  },
  computed: {
    ...mapGetters({
      users: "usersModule/getUsers",
      getCurrentUserInRole: "getCurrentUserInRole",
      getUserByEmailAddress: "usersModule/getUserByEmailAddress",
      getDisplayRole: "usersModule/getDisplayRole",
      providers: "providersModule/getProviders",
      account: "getCurrentUser",
    }),
    isProlitecUser() {
      if (this.currentUser.email.split("@")[1] == "prolitec.com") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async onProviderSelected() {
      this.isLoading = true;
      this.showChooseLogo = true;
      if (this.account && this.account.username) {
        await this.$store.dispatch("usersModule/fetchAllUsers");
        this.currentExtendedUser = this.getUserByEmailAddress(
          this.account?.username
        );
      }
      this.isLoading = false;
    },
    async fetchImages(provider) {
      this.modal = new Modal(document.getElementById("galleryModal"));
      this.modal.show();
      this.isLoading = true;
      try {
        const response = await axios.get(
          `https://prolitecdataservices.azurewebsites.net/api/vseGetUserLogo?Provider=${provider}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-functions-key":
                "aT9mFFAfdQyUECvcY4Td2MOqDzZGkCkCLfd87hh1NheIAzFuco4XCQ==",
              "Referrer-Policy": "origin-when-cross-origin",
            },
          }
        );
        this.images = response.data.images;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    },
    confirmSelection() {
      if (this.selectedImage) {
        //   alert(`Selected Image: ${this.selectedImage}`);
        this.modal.hide();
        this.finalSelectedImage = this.selectedImage;
        // this.currentUser.logo = this.finalSelectedImage.split('?')[0];
        this.currentUser.logo = this.finalSelectedImage;
      } else {
        alert("Please select an image!");
      }
    },
    selectImage(image) {
      this.selectedImage = image;
    },
    getProviderName(providerId) {
      const selectedProvider = this.providers.find(
        (provider) => provider.id === providerId
      );
      return selectedProvider ? selectedProvider.name : "";
    },
    removeImage() {
      this.finalSelectedImage = "";
    },
    enableProviderByRole() {
 
      if (this.getCurrentUserInRole("Task.Admin")) {
        return false;
      } else {
        return true;
      }
    },
    populateUser() {
      var UserByRoute = this.$store.getters["usersModule/getUserById"](
        this.$route.params.userId
      );
      this.currentUser = UserByRoute;
      if (this.currentUser?.logo) {
        this.finalSelectedImage = this.currentUser.logo;
        this.showCrossSign = false;
      }
      var newCrumb = {
        name: this.currentUser?.firstName + " " + this.currentUser?.lastName,
        url: "/users/" + this.$route?.params?.userId,
      };
      this.crumbs.push(newCrumb);
      return;
    },
    showPageStateEdit() {
      this.pageState = "edit";
      this.showCrossSign = true;
      this.showChooseLogo = !!this.currentUser.provider; // Only show if a provider is selected
    },
    showPageStateView() {
      this.pageState = "view";
      this.showCrossSign = false;
    },
    async validateAndSaveUser() {
      this.errorMessages = [];
      var isValid = true;
      let existingVseUser = this.getUserByEmailAddress(
        this.currentUser.email.toLowerCase()
      );
      let userVseExists =
        existingVseUser != null && existingVseUser.id.length > 0 ? true : false;
      // check for id

      // check for text in short, long description
      if (
        this.currentUser.firstName == null ||
        this.currentUser.firstName.length == 0
      ) {
        isValid = false;
        this.errorMessages.push("First Name is required.");
      }
      if (
        this.currentUser.lastName == null ||
        this.currentUser.lastName.length == 0
      ) {
        isValid = false;
        this.errorMessages.push("Last Name is required.");
      }
      // check for selected family, collection
      if (
        this.currentUser.email == null ||
        this.currentUser.email.length == 0
      ) {
        isValid = false;
        this.errorMessages.push("Email is required.");
      }
      if (
        this.currentUser.provider == null ||
        this.currentUser.provider.length == 0
      ) {
        isValid = false;
        this.errorMessages.push("Provider is required.");
      }
      if (
        this.currentUser.role == null ||
        this.currentUser.role.length == 0 ||
        this.currentUser.role == "0"
      ) {
        isValid = false;
        this.errorMessages.push("Role is required.");
      }
      this.currentUser.logo = this.finalSelectedImage;
      this.currentUser.language = existingVseUser.language;
      //console.log('isValid? ' + isValid + '; errors: ' + this.errorMessages.join(','));

      if (isValid == true) {
        this.isUserSaving = true;
        // check if existing user in Azure AD
        this.$store
          .dispatch("usersModule/findExistingAdUser", this.currentUser)
          .then((adUser) => {
            let promisesToCall = [];
       
            if (adUser != null && adUser.id.length > 12) {
              this.currentUser.activeDirectoryMemberId = adUser.id;
              this.currentUser.member = adUser;
              // user exists in AD...if user exists in Vse.UserSettings, update...else add
              promisesToCall.push(
                this.$store.dispatch(
                  "usersModule/updateUserRoles",
                  this.currentUser
                )
              );
              if (userVseExists) {
                // user exists in VSE...update
                promisesToCall.push(
                  this.$store.dispatch(
                    "usersModule/updateUserSettings",
                    this.currentUser
                  )
                );
              } else {
                this.currentUser.joined = new Date();
                promisesToCall.push(
                  this.$store.dispatch(
                    "usersModule/addUserSettings",
                    this.currentUser
                  )
                );
              }

              Promise.all(promisesToCall).then(() => {
                // redirect to view user with success message
                this.showSaveSuccess = true;
                this.isUserSaving = false;
                this.showPageStateView();
              });
            } else {
              // invite user and get ID
              this.$store
                .dispatch("usersModule/inviteUser", this.currentUser)
                .then((newUser) => {
                  // user memberId to add to groups
                  this.currentUser.activeDirectoryMemberId = newUser.id;
                  if (userVseExists) {
                    // user exists in VSE...update
                    promisesToCall.push(
                      this.$store.dispatch(
                        "usersModule/updateUserSettings",
                        this.currentUser
                      )
                    );
                    if (this.user.role != existingVseUser.role) {
                      promisesToCall.push(
                        this.$store.dispatch(
                          "usersModule/updateUserRoles",
                          this.currentUser
                        )
                      );
                    }
                  } else {
                    this.currentUser.joined = new Date();
                    promisesToCall.push(
                      this.$store.dispatch(
                        "usersModule/addUserSettings",
                        this.currentUser
                      )
                    );
                    promisesToCall.push(
                      this.$store.dispatch(
                        "usersModule/updateUserRoles",
                        this.currentUser
                      )
                    );
                  }
                  Promise.all(promisesToCall).then(() => {
                    // redirect to view user with success message
                    this.showSaveSuccess = true;
                    this.isUserSaving = false;
                    this.showPageStateView();
                  });
                })
                .catch((error) => {
                  console.error(
                    "API Error, could not add user. Please try again later.",
                    error
                  );
                  isValid = false;
                  this.errorMessages.push(
                    "API Error, could not add user. Please try again later."
                  );
                  this.showInvalidForm = true;
                });
            }
          });
        this.showChooseLogo = true;
        this.showCrossSign = false;
      } else {
        // show error message
        this.showInvalidForm = true;
      }
    },
    deleteUserWarning() {
      // show modal
      this.isDeleteModalVisible = true;
    },
    async deleteUser() {
      let deletedUserName =
        this.currentUser.firstName + " " + this.currentUser.lastName;
      let deletedUserId = this.currentUser.id;
      this.currentUser.role = "0";
      let promisesToCall = [];
      promisesToCall.push(
        this.$store.dispatch("usersModule/deleteUserSetting", this.currentUser)
      );
      promisesToCall.push(
        this.$store.dispatch("usersModule/updateUserRoles", this.currentUser)
      );
      this.isDeleting = true;
      Promise.all(promisesToCall).then(() => {
        // redirect to view user with success message
        this.closeDeleteModal();
        this.isDeleting = false;
        this.$router.push(
          "/users/" + deletedUserId + "/delete/" + deletedUserName
        );
      });
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
    },
    onCloseErrorMessageModal() {
      this.showInvalidForm = false;
      this.errorMessages = [];
    },
    canEditUser() {
      if (this.getCurrentUserInRole("Task.Admin")) return true;
      else if (
        this.getCurrentUserInRole("Task.Manager") &&
        this.currentUser &&
        this.currentUser.role == VUE_APP_VSE_USER_GROUP_ID
      )
        return true;
      return false;
    },
    canView(optionName) {
      if (this.getCurrentUserInRole("Task.Admin")) return true;
      else if (this.getCurrentUserInRole("Task.Manager")) {
        if (this.pageState == "view") return true;
        else if (
          this.pageState == "edit" &&
          this.groupConstants.admin == optionName
        )
          return false;
        else if (
          this.pageState == "edit" &&
          this.groupConstants.manager == optionName
        )
          return false;
      }
    },
  },
};
</script>
<style scoped>
/* Scrollable Gallery Container */
.image-gallery-container {
  display: flex; /* Use flex layout */
  flex-direction: row; /* Arrange images in a row */
  flex-wrap: wrap; /* Allow images to wrap to the next line */
  gap: 30px; /* Space between images */
  padding: 10px; /* Padding around the container */
  max-height: 200px; /* Set max height for the container to trigger scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

/* Styling for each image */
.image-item {
  width: 150px; /* Set a fixed width for each image item */
  height: auto; /* Maintain aspect ratio */
  display: flex; /* Use flex for positioning the content inside each item */
  position: relative; /* Needed for absolute positioning of the radio button */
  flex-shrink: 0; /* Prevent shrinking of the image */
}

/* Image Styling */
.gallery-img {
  width: 100%; /* Make the image fill the container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the space without distortion */
  border-radius: 8px; /* Optional: rounded corners */
  cursor: pointer; /* Change cursor to indicate clickable image */
  transition: transform 0.2s ease-in-out; /* Smooth transition on hover */
}
/* Hover Effect (Optional) */
.gallery-img:hover {
  transform: scale(1.1); /* Slightly enlarge the image on hover */
}

/* Positioning for the radio button */
input[type="radio"] {
  z-index: 2; /* Make sure the radio button is on top of the image */
  cursor: pointer;
  position: absolute; /* Position the radio button on top of the image */
  top: 10px;
  left: 10px;
}
/* Positioning for the cross sign */
.cross-btn {
  z-index: 2; /* Ensure the cross button is on top of the image */
  position: absolute; /* Position the cross button on top of the image */
  top: -5px;
  right: -5px;
  background: red; /* Red background for visibility */
  border: none; /* Remove border */
  color: white; /* White cross sign */
  font-size: 1rem; /* Adjust size of the cross sign */
  width: 15px; /* Set fixed width */
  height: 15px; /* Set fixed height */
  border-radius: 10%; /* Make it a circular button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Change cursor to indicate clickable button */
}

.image-container {
  position: relative; /* Ensures the cross button is positioned correctly */
  display: inline-block; /* Keeps the image container size appropriate */
}
</style>
