<template>
    <section v-if="currentQuestion != null" id="QuestionAdd" class="container">
        <!-- Choose Publish Status -->
        <!-- Choose Type (modal?) -->
        <!-- Enter Question Text -->
        <!-- View Answer List -->
        <!-- View Answer Detail -->
        <div class="row mt-4">
            <breadcrumbVue :items="crumbs"></breadcrumbVue>
        </div>
        <nav class="navbar navbar-edit navbar-expand fixed-top justify-content-between">
            <div class="container">
                <span v-if="currentQuestion.type != null">Add {{ currentQuestion.type }} Question: </span>
                <span>
                    <button @click="goBackToQuestionList" class="btn btn-secondary me-3">Cancel</button>
                    <button @click="validateAndSaveQuestion" class="btn btn-primary">
                    <span>Save</span>
                    <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </span> 
            </div>
        </nav>
        <h1>Create Question</h1>
        <form @submit.prevent="validateAndSaveQuestion">
            <input v-model="currentQuestion.type" type="hidden" />
            <!-- Fragrance Form -->
            <div class="row mt-4">
                <div class="col">
                    <label for="selectPublishState" class="form-label">Visibility</label>
                    <select v-if="pageState != 'view'" id="selectPublishState" v-model="currentQuestion.publishState" class="form-select" aria-label="Visibility">
                    <option disabled value="">Select an option</option>
                    <option v-for="option in publishedStateOptions" v-bind:key="option" :value="option">{{ option }}</option>
                    </select>
                    <p v-else class="fs-5" :class="currentQuestion.publishState == 'Published' ? 'text-success' : 'text-danger'">{{ currentQuestion.publishState }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="inputQuestionText" class="form-label">Question</label>
                    <input id="inputQuestionText" v-model="currentQuestion.questionText" type="text" class="form-control" placeholder="Question" aria-label="Question Text" />
                </div>
            </div>
        </form>
        <!-- COLORS -->

        <!-- ADJECTIVES -->
        <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
    </section>
</template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import errorMessageModal from '../../components/error-message-modal.vue';
import {mapGetters } from 'vuex';
import { delay } from '../../utils/helpers.js';

export default {
    name: "QuestionAdd",
    components: {
        breadcrumbVue,
        errorMessageModal
    },
    data() {
        return {
            crumbs: [
                {
                    name: "Dashboard",
                    url: "/dashboard",
                },
                {
                    name: "Questions",
                    url: "/questions",
                },
                {
                    name: "Create Question",
                    url: "/questions/add",
                },
            ],
            isLoading: false,
            isSaving: false,
            showSaveSuccess: false,
            errorMessages: [],
            errorTitle: 'Error Adding Question',
            showInvalidForm: false,
        };
    },
    computed: {
        ...mapGetters({
            publishedStateOptions: 'fragrancesExtendedModule/getPublishedStateOptions',
        }),
        currentQuestion() {
            return this.$store.getters['questionsModule/getCurrentQuestion'];
        },
    },
    methods: {
        async validateAndSaveQuestion() {
            // validate fragrance
            var newQuestionId = '';
            this.errorMessages = [];
            var isValid = true;
            if (this.currentQuestion.type == null || this.currentQuestion.type.length < 1) {
                isValid = false;
                this.errorMessages.push('There was a problem saving the answer.');
            }
            if (this.currentQuestion.publishState == null || this.currentQuestion.publishState.length < 1) {
                isValid = false;
                this.errorMessages.push('Publish State is required.');
            }
            if (this.currentQuestion.questionText == null || this.currentQuestion.questionText.length < 1) {
                isValid = false;
                this.errorMessages.push('Question Text is required.');
            }
            this.currentQuestion.answers = [];
            // set publish date to NOW
            this.currentQuestion.publishDate = new Date();

            if (isValid) {
                // add loading spinner...
                this.isSaving = true;
                // save fragrance, get ID in promise
                await this.$store.dispatch('questionsModule/addQuestion', this.currentQuestion).then(() => {
                    // add saved success message
                    this.showSaveSuccess = true;
                    this.isSaving = false;
                    delay(1000);
                    this.$router.push('/questions/' + newQuestionId);
                }); 
            } else {
                this.showInvalidForm = true;
            }
        },
        goBackToQuestionList() {
            // clear current question...
            this.$store.commit("questionsModule/CLEAR_CURRENT_QUESTION");
            this.$router.push('/questions/');
        },
        onCloseErrorMessageModal() {
            this.showInvalidForm = false;
            this.errorMessages = [];
        }
    },
};
</script>
