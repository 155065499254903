<template>
    <section id="QuestionDetail" class="container">
        <div class="row mt-4">
            <breadcrumbVue :items="crumbs"></breadcrumbVue>
        </div>
        <div v-if="pageState == 'question.edit' || pageState == 'answer.edit' || pageState == 'answer.add'">
            <nav class="navbar navbar-edit navbar-expand fixed-top justify-content-between">
                <div class="container">
                    <span v-if="pageState == 'question.edit'">Edit Question: {{ currentQuestion.questionText }}</span>
                    <span v-if="pageState == 'answer.add'">Add Answer</span>
                    <span v-if="pageState == 'answer.edit'">Edit Answer: {{ currentAnswer.title }}</span>
                    <span>
                        <button v-if="pageState == 'question.edit'" @click="showPageState('question.view')" class="btn btn-secondary me-3">Cancel</button>
                        <button v-if="pageState == 'answer.edit'" @click="showPageState('answer.view')" class="btn btn-secondary me-3">Cancel</button>
                        <button v-if="pageState == 'answer.add'" @click="showPageState('question.view')" class="btn btn-secondary me-3">Cancel</button>
                        <button v-if="pageState == 'question.edit'" @click="validateAndSaveQuestion('question.view')" class="btn btn-primary">
                            <span>Save</span>
                            <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        </button>
                        <button v-if="pageState == 'answer.add'" @click="validateAndSaveAnswer('answer.add')" class="btn btn-primary">
                            <span>Save</span>
                            <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        </button>
                        <button v-if="pageState == 'answer.edit'" @click="validateAndSaveAnswer('answer.edit')" class="btn btn-primary">
                            <span>Save</span>
                            <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        </button>
                    </span> 
                </div>
            </nav>
        </div> 
        <div v-if="pageState == 'question.view'">
            <h1 style="margin-top:-.3em;">{{ currentQuestion.questionText }}</h1>
        </div> 
        <div v-if="pageState == 'answer.view'">
            <h1 style="margin-top:-.3em;">{{ currentAnswer.title }}</h1>
        </div> 

        <!-- QUESTION VIEW -->
        <div v-if="pageState == 'question.view'">
            <label class="form-label">Visibility</label>
            <p class="fs-5" :class="currentQuestion.publishState == 'Published' ? 'text-success' : 'text-danger'">{{ currentQuestion.publishState }}</p>
            <h2>Answers</h2>
            <table v-if="currentQuestion.answers.length > 0" class="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th class="sortable" @click="sort('title')">Answer</th>
                        <th>Visibility</th>
                        <th class="sortable" @click="sort('adjectives.length')">Adjectives</th>
                    </tr>
                </thead>
                <draggable v-model="currentQuestion.answers" tag="tbody" handle=".handle" :move="checkMove" class="dragArea w-full"
                @start="dragging = true"
                @end="dragging = false">
                    <tr v-for="(answer, index) in currentQuestion.answers" :key="answer.title">
                        <td><BIconGripHorizontal v-if="isSortOrderEditable" class="handle" /><span v-else>{{ answer.sortOrder }}</span></td>
                        <td><a @click="viewAnswer(index)" class="link link-primary">{{ answer.title }}</a></td>
                        <td>{{ answer.publishState }}</td>
                        <td>{{ answer.adjectives.length }}</td>
                    </tr>
                </draggable>
            </table>
            <p v-else>No answers yet.</p>

            <!-- UNUSED ADJECTIVES -->
            <h3 class="mt-5">Unused adjectives for this question ({{ unusedAdjectives.length }}/{{ adjectives.length }})</h3>
            <adjectiveDisplayList v-if="unusedAdjectives.length > 0" :displayAdjectives="unusedAdjectives"></adjectiveDisplayList>
            <p v-else>All adjectives used.</p>

            <!-- ACTIONS -->
            <div class="row my-5">
                <div class="col">
                    <h2>Actions</h2>
                    
                    <button v-if="!isSortOrderEditable" @click="showPageState('question.edit')" class="btn btn-primary me-2">Edit Question</button>
                    <button v-if="!isSortOrderEditable" class="btn btn-primary me-2" @click="allowSortOrderEdit">Edit Answer Order</button>
                    <button v-if="isSortOrderEditable" class="btn btn-secondary me-2" @click="cancelSortOrderEdit">Cancel</button>
                    <button v-if="isSortOrderEditable" class="btn btn-primary me-2" @click="saveSortOrderEdit">
                        <span>Save</span>
                        <span v-show="isSortOrderUpdating" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                    <button v-if="!isSortOrderEditable" @click="addAnswer('answer.add')" class="btn btn-primary">Add Answer</button>
                </div>
            </div>

            <!-- DELETE -->
            <div class="row my-5">
                <div class="col">
                    <h2>Danger Zone</h2>
                    <button @click="deleteQuestionWarning" class="btn btn-danger">Delete</button>
                </div>
            </div>
        </div>
        <!-- END QUESTION VIEW -->

        <!-- QUESTION EDIT -->
        <div v-if="pageState == 'question.edit'">
            <!-- VISIBILITY -->
            <div class="row mt-4">
                <div class="col">
                    <label for="selectPublishState" class="form-label">Visibility</label>
                    <select id="selectPublishState" v-model="currentQuestion.publishState" class="form-select" aria-label="Visibility">
                        <option disabled value="">Select an option</option>
                        <option v-for="option in publishedStateOptions" v-bind:key="option" :value="option">{{ option }}</option>
                    </select>
                </div>
            </div>
            <!-- QUESTION TEXT -->
            <div class="row">
                <div class="col">
                    <label for="inputQuestionText" class="form-label">Question</label>
                    <input id="inputQuestionText" v-model="currentQuestion.questionText" type="text" class="form-control" placeholder="Question" aria-label="Question Text" />
                </div>
            </div>
        
        </div>
        <!-- END QUESTION EDIT -->

        <!-- ANSWER ADD/EDIT -->
        <div v-if="pageState == 'answer.add' || pageState == 'answer.edit'">
            <!-- PUBLISH STATE -->
            <div class="row mt-4">
                <div class="col">
                    <label for="selectPublishState" class="form-label">Visibility</label>
                    <select id="selectPublishState" v-model="currentAnswer.publishState" class="form-select" aria-label="Visibility">
                        <option disabled value="">Select an option</option>
                        <option v-for="option in publishedStateOptions" v-bind:key="option" :value="option">{{ option }}</option>
                    </select>
                </div>
            </div>
            <!-- IMAGE (if type = photo)-->
            <div v-if="currentAnswer.type == 'Photo + Text'" class="row my-3">
                <div class="col">
                    <div id="FileUploadAnswerImage" class="input-group flex-column align-items-start mb-3">
                        <label class="my-3" for="inputFileHeroImage">Hero Image</label>
                        <img v-show="currentAnswer.heroImageUrl != null" :src="currentAnswer.heroImageUrl" class="img-hero" width="160" />
                        <input type="file" class="upload upload-image visually-hidden" @change="uploadHeroImage"  accept=".jpg,.gif,.png" id="inputFileHeroImage">
                        <label class="upload-status text-primary" id="labelHeroImageUploadStatus"></label>
                        <label v-show="pageState != 'view'" class="input-group-text btn btn-primary mt-3" for="inputFileHeroImage">
                            <span>Upload</span>
                            <span v-show="isHeroImageLoading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        </label>
                        <div> 
                            <span v-show="currentAnswer.heroImageUrl == null" class="text-danger">Please upload an image</span>
                        </div>
                        <!--<p>{{ downloadHeroImageUrl }}</p>-->
                    </div>
                </div>
            </div>
            <!-- TITLE -->
            <div class="row">
                <div class="col">
                    <label for="inputAnswerText" class="form-label">Title</label>
                    <input id="inputAnswerText" v-model="currentAnswer.title" type="text" class="form-control" placeholder="Title" aria-label="Answer Title" />
                </div>
            </div>
            <!-- ADJECTIVES -->   
            <adjectiveSelectedList @addAdjective="addAdjectiveToCurrentAnswer" @removeAdjective="removeAdjectiveFromCurrentAnswer" :selectedAdjectives="currentAnswer.adjectives"></adjectiveSelectedList>      
        </div>
        <!-- END ANSWER ADD/EDIT -->

        <!-- ANSWER VIEW -->
        <div v-if="pageState == 'answer.view'">
            <!-- PUBLISH STATE -->
            <div class="row mt-4">
                <div class="col">
                    <label class="form-label">Visibility</label>
                    <p class="fs-5" :class="currentAnswer.publishState == 'Published' ? 'text-success' : 'text-danger'">{{ currentAnswer.publishState }}</p>
                </div>
            </div>
            <!-- IMAGE (if type = photo)-->
            <div v-if="currentAnswer.type == 'Photo + Text'" class="row my-3">
                <div class="col">
                    <div id="FileUploadAnswerImage" class="input-group flex-column align-items-start mb-3">
                        <label class="my-3" for="inputFileHeroImage">Hero Image</label>
                        <img v-show="currentAnswer.heroImageUrl != null" :src="currentAnswer.heroImageUrl" class="img-hero" width="160" />
                        <!--<p>{{ downloadHeroImageUrl }}</p>-->
                    </div>
                </div>
            </div>
            <!-- TITLE -->
            <div class="row">
                <div class="col">
                    <label for="inputAnswerText" class="form-label">Title</label>
                    <p>{{ currentAnswer.title }}</p>
                </div>
            </div>
            <!-- ADJECTIVES -->   

            <!-- ACTIONS -->
            <div class="row my-5">
                <div class="col">
                    <h2>Actions</h2>
                    <button @click="showPageState('answer.edit')" class="btn btn-primary">Edit</button>
                </div>
            </div>
            
            <!-- DELETE -->
            <div class="row my-5">
                <div class="col">
                    <h2>Danger Zone</h2>
                    <button @click="deleteAnswerWarning" class="btn btn-danger">Delete</button>
                </div>
            </div>
        </div>
        <!-- END ANSWER VIEW -->

        <!-- ANSWER DELETED VIEW -->
        <div v-if="pageState == 'answer.delete'">
            <div class="col">
                <h1>Deleted {{ deletedAnswerTitle }}</h1>
                <p>{{ deletedAnswerTitle }} has been deleted.</p>
                <a @click="showPageState('question.view')" class="link link-primary">Return to Question</a>
            </div> 
        </div>
        <!-- END ANSWER DELETED VIEW -->

        <!-- DELETE QUESTION MODAL -->
        <div v-if="isDeleteModalVisible" class="modal fade show" id="myModal" style="display: block;">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Delete Question</h4>
                        <button type="button" class="btn-close" @click="closeDeleteModal" data-dismiss="modal" aria-label="Close">
                            <span class="visually-hidden" aria-hidden="true" >×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p id="FragranceDeleteText">Are you sure you want to delete <strong>{{ currentQuestion.questionText }}</strong>?</p>
                        <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeDeleteModal" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="deleteQuestion(currentQuestion.id)" data-dismiss="modal">
                            <span>Confirm</span>
                            <span v-show="isQuestionDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDeleteModalVisible" class="modal-backdrop fade show"></div>

        <!-- DELETE ANSWER MODAL -->
        <div v-if="isDeleteAnswerModalVisible" class="modal fade show" id="myModal" style="display: block;">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Delete Answer</h4>
                        <button type="button" class="btn-close" @click="closeDeleteAnswerModal" data-dismiss="modal" aria-label="Close">
                            <span class="visually-hidden" aria-hidden="true" >×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p id="FragranceDeleteText">Are you sure you want to delete <strong>{{ currentAnswer.title }}</strong>?</p>
                        <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeDeleteAnswerModal" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="deleteAnswer(currentAnswer)" data-dismiss="modal">
                            <span>Confirm</span>
                            <span v-show="isAnswerDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDeleteAnswerModalVisible" class="modal-backdrop fade show"></div>
        <!-- END: DELETE ANSWER MODAL -->

        <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
    </section>
</template>
<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import adjectiveSelectedList from '../../components/answer-adjective-select.vue'; 
import adjectiveDisplayList from '../../components/adjective-display-list.vue';  
import errorMessageModal from '../../components/error-message-modal.vue';
import { VueDraggableNext } from "vue-draggable-next";
import { uploadFile } from '../../utils/files.js';
import {mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'QuestionDetail',
    components: {
        breadcrumbVue,
        adjectiveSelectedList,
        adjectiveDisplayList,
        draggable: VueDraggableNext,
        errorMessageModal
    },
    data() {
        return {
            crumbs: [{
                name: 'Dashboard',
                url: '/dashboard'
                    }, {
                        name: 'Questions',
                        url: '/questions'
                }
            ],
            pageState: 'question.view', // pageStates: 'question.view', 'question.edit', 'question.delete', 'answer.view', 'answer.add', 'answer.edit', 'answer.delete'
            currentAnswer: null,
            deletedAnswerTitle: '',
            errorMessages: [],
            errorTitle: 'Error Saving Question',
            showInvalidForm: false,
            isDeleteModalVisible: false,
            isDeleteAnswerModalVisible: false,
            isLoading: false,
            isDeleting: false,
            isQuestionDeleting: false,
            isAnswerDeleting: false,
            isHeroImageLoading: false,
            isAnswerSaving: false,
            isQuestionSaving: false,
            isSaving: false,
            isHeroImageAdded: false,
            isSortOrderEditable: false,
            isSortOrderUpdating: false,
            display: "Handle",
            order: 1,
            instruction: "Drag using the handle icon",
            dragging: false
        }
    },
    created() {
        this.populateQuestion();
    },
    computed: {
         ...mapGetters({
            publishedStateOptions: 'questionsModule/getPublishedStateOptions',
            questionTypeOptions: 'questionsModule/getQuestionTypeOptions',
            questions: 'questionsModule/getQuestions',
        }),
        currentQuestion() {
            return this.$store.getters['questionsModule/getCurrentQuestion'];
        },
        adjectives() {
            return this.$store.getters['adjectivesModule/getAdjectives'];    
        },
        unusedAdjectiveCount() {
            return this.adjectives.length - this.unusedAdjectives.length;
        }

    },
    methods: {
        async populateQuestion() {
            var currentQ = this.$store.getters["questionsModule/getQuestionById"](this.$route.params.questionId);
            this.$store.commit('questionsModule/SET_CURRENT_QUESTION', currentQ);
            this.sort('sortOrder');
            
            var allAdjectives = this.$store.getters['adjectivesModule/getAdjectives']; 
            if (allAdjectives == null || allAdjectives.length < 1) {
                await this.$store.dispatch("adjectivesModule/fetchAdjectives").then(adjectives => {
                    allAdjectives = adjectives;
                });
            }
            var usedAdjs = [];
            this.currentQuestion.answers.forEach(answer =>  {
                usedAdjs = usedAdjs.concat(answer.adjectives);
            });
            this.usedAdjectives = usedAdjs;
            //let usedAdjectivesSet = new Set(this.usedAdjectives);

     
            //this.unusedAdjectives = allAdjectives.filter(x => !this.usedAdjectives.includes(x.id));
            this.unusedAdjectives = allAdjectives.filter((elem) => !this.usedAdjectives.find(({ id }) => elem.id === id));

            var newCrumb = { name: this.currentQuestion.questionText, url: '/questions/' + this.$route.params.questionId };
            this.crumbs.push(newCrumb);

            return;
        },
        async uploadHeroImage(e) {
            this.isHeroImageLoading = true;
            var file = e.target.files[0];
            var filename = e.target.files[0].name;
            this.isHeroImageAdded = true;
            let payload = new FormData();
                payload.append('file', file);
            var payloadObject = {
                filename: filename,
                file: payload
            }
       
            await uploadFile(payloadObject).then(downloadUrl => {
                this.currentAnswer.heroImageUrl = downloadUrl;
                this.isHeroImageLoading = false;
            });    
        },
        sort:function(s) {
        //if s == current sort, reverse
        //   if(s === this.currentSort) {
        //     this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
        //   }
            this.currentSort = s;
            this.currentQuestion.answers = this.getSortedAnswers();
        },
        getSortedAnswers:function() {
            return this.currentQuestion.answers.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
            });
        },
        viewAnswer(index) {
            this.pageState = 'answer.view';
            this.currentAnswer = this.currentQuestion.answers[index];
            this.addAnswerCrumb();
        },
        addAnswer() {
            this.currentAnswer = {
                id: uuidv4(),
                title: '',
                publishState: 'Draft',
                type: this.currentQuestion.type,
                sortOrder: this.currentQuestion.answers.length + 1,
                adjectives: []
            };
            this.showPageState('answer.add');
            
        },
        cancelAddAnswer() {

        },
        addAnswerCrumb() {
            var newCrumb = { name: this.currentAnswer.title, url: '/questions/' + this.$route.params.questionId + '/' + this.currentAnswer.title };
            this.crumbs.push(newCrumb);
        },
        removeAnswerCrumb() {
            if (this.crumbs.length == 4) {
                this.crumbs.pop();
            }
        },
        async validateAndSaveAnswer(mode) {
            var isValid = true;
            this.errorMessages = [];
            if (this.currentAnswer == null) {
                isValid = false;
                this.errorMessages.push('There was a problem saving the answer.');
            }
            // check for type...if photo, ensure url exists
            if (this.currentAnswer.type.includes('Photo') 
            && (this.currentAnswer.heroImageUrl == null || this.currentAnswer.heroImageUrl.length < 5)) {
                isValid = false;
                this.errorMessages.push('A Hero Image is required.');
            }

            // check for title
            if (this.currentAnswer.title.length < 1) {
                isValid = false;
                this.errorMessages.push('An answer title is required.');
            }

            // check for adjectives
            if (this.currentAnswer.adjectives.count < 3) {
                isValid = false;
                this.errorMessages.push('At least 3 adjectives are required.');
            }

            // update Question with answers
            if (isValid) {
                this.isSaving = true;
                const index = this.currentQuestion.answers.findIndex(x => x.id === this.currentAnswer.id);
                if (mode == 'edit' || index > -1) {
                    // if found or in edit mode, update
                    this.$store.commit('questionsModule/UPDATE_CURRENT_QUESTION_ANSWER', this.currentAnswer);
                } else {
                    this.$store.commit('questionsModule/ADD_CURRENT_QUESTION_ANSWER', this.currentAnswer);
                    
                }
                
                await this.$store.dispatch('questionsModule/updateQuestion', this.currentQuestion).then(() => {
                    this.isSaving = false;
                });
                this.pageState = 'answer.view';
                this.addAnswerCrumb()
                // update breadcrumb
            } else {
                this.showInvalidForm = true;
            }
        },
        async validateAndSaveQuestion(requestedPageState) {
            this.errorMessages = [];
            var isValid = true;
            if (this.currentQuestion == null) {
                isValid = false;
                this.errorMessages.push('There was a problem saving the question.');
            }

            // check for question text
            if (this.currentQuestion.questionText.length < 1) {
                isValid = false;
                this.errorMessages.push('Question Text is required.');
            }

            // update Question with answers
            if (isValid) {
                this.isSaving = true;
                
                await this.$store.dispatch('questionsModule/updateQuestion', this.currentQuestion).then(() => {
                    this.isSaving = false;
                });
                if (null != requestedPageState) {
                    this.pageState = requestedPageState;
                } else {
                    this.pageState = 'question.view';
                }
                
            } else {
                this.showInvalidForm = true;
            }
        },
        showPageState(pageState) {
            this.pageState = pageState;
            if (pageState.includes('uestion')) {
                this.removeAnswerCrumb();
            }
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        addAdjectiveToCurrentAnswer(newAdjective) {
   
            const index = this.currentAnswer.adjectives.findIndex(x => x.id === newAdjective.id);
            if (index == undefined || index == -1) {
                this.currentAnswer.adjectives.push(newAdjective);
            }
        },
        removeAdjectiveFromCurrentAnswer(adjectiveId) {

            const index = this.currentAnswer.adjectives.findIndex(x => x.id === adjectiveId);
            if (index >= 0 && index < this.currentAnswer.adjectives.length) {
                this.currentAnswer.adjectives.splice(index, 1);
            }
        },
        showPageStateView() {
            this.pageState = 'view';
        },
        deleteQuestionWarning() {
            this.isDeleteModalVisible = true;
        },
        closeDeleteModal() {
            this.isDeleteModalVisible = false;
        },
        async deleteQuestion(questionId) {
            let deletedQuestionName = this.currentQuestion.questionText;

            this.isQuestionDeleting = true;
            await this.$store.dispatch('questionsModule/deleteQuestion', questionId).then(() => {
                this.saveQuestionSortOrderEdit().then(() => {
                    this.isDeleteModalVisible = false;
                    this.isQuestionDeleting = false;
                    this.$router.push('/questions/' + questionId + '/delete/' + deletedQuestionName);
                });
                
            }); 
        },
        async saveQuestionSortOrderEdit() {
        // update each question's sort order, then save
                // set sort order...loop through each item and set sort order = index + 1
        let allQuestions = this.questions;
        let questionsCount = allQuestions.length;

        for (var [index, question] of allQuestions.entries()) {
            question.sortOrder = index + 1;
            await this.$store.dispatch('questionsModule/updateQuestion', question).then(() => {
                if (index == questionsCount - 1) {
                    this.isSortOrderUpdating = false;
                } else {
                    console.log('Continue updating...');
                }
            });
        }
    },
        deleteAnswerWarning() {
            this.isDeleteAnswerModalVisible = true;
        },
        closeDeleteAnswerModal() {
            this.isDeleteAnswerModalVisible = false;
        },
        async deleteAnswer(answerToDelete) {
            this.deletedAnswerTitle = answerToDelete.title;
            this.isDeleteAnswerModalVisible = false;
            this.isAnswerDeleting = true;
            // TODO: implememt delete answer (update question) logic
            var _updatedAnswers = this.currentQuestion.answers.filter(answer => answer.id != answerToDelete.id);
            this.currentQuestion.answers = _updatedAnswers;
            await this.validateAndSaveQuestion('answer.delete').then(() => {
                this.isAnswerDeleting = false;
                this.showPageState('answer.delete');             
            });
            
        },
        allowSortOrderEdit() {
            this.isSortOrderEditable = true;
        },
        cancelSortOrderEdit() {
            this.isSortOrderEditable = false;
            this.sort('sortOrder');
        },
        async saveSortOrderEdit() {
            // update each question's sort order, then save
                    // set sort order...loop through each item and set sort order = index + 1
            this.isSortOrderUpdating = true;
            for (var [index, answer] of this.currentQuestion.answers.entries()) {
                answer.sortOrder = index + 1;
            }

            await this.$store.dispatch('questionsModule/updateQuestion', this.currentQuestion).then(() => {
                this.isSortOrderUpdating = false;
            });

            this.isSortOrderEditable = false;
        },
        checkMove: function(e) {
            let id = e.draggedContext.element.id;
            console.log(id);
        },
        onCloseErrorMessageModal() {
            this.showInvalidForm = false;
            this.errorMessages = [];
        }
    }
}
</script>
