<template>
    <nav v-if="items && items.length"
        class="my-breadcrumbs">
        <ol class="breadcrumb">
            <li v-for="(item, itemIndex) in items" :key="itemIndex" class="breadcrumb-item">
                <router-link v-if="itemIndex !== items.length - 1"
                    :to="item.url"
                    :title="item.name"
                    class="bc-link"
                >
                    {{ item.name }}
                </router-link>
                <span v-else class="bc-name">{{ item.name }}</span>
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: { items: {
    type: Array,
    required: true
  }}
};
</script>
