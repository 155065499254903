<template>
    <section id="ProviderInventory" class="container">
      <div class="row mt-4">
          <breadcrumbVue :items="crumbs"></breadcrumbVue>
      </div>
      <div v-if="pageState != 'view'">
          <nav class="navbar navbar-edit navbar-expand fixed-top justify-content-between">
              <div class="container">
                  <span>{{ currentProvider.name }}</span>
                  <span>
                      <button @click="showPageStateView" class="btn btn-secondary me-3">Cancel</button>
                      <button @click="validateAndSaveProvider" class="btn btn-primary">
                          <span>Save</span>
                          <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                      </button>
                  </span> 
              </div>
          </nav>
      </div> 
      <div v-else>
          <h1 style="margin-top:-.3em;">{{ currentProvider.name }}</h1>
      </div>
      <div v-if="showSaveSuccess == true">
          <div class="alert fade show alert-success" role="alert">
          Your changes were saved.
          </div>
      </div>
            <!-- ACTIONS -->
      <div v-if="getCurrentUserInRole('Task.Manager') || getCurrentUserInRole('Task.Admin')" class="row my-5">
          <div class="col">
              <h2>Actions</h2>
              <button @click="showPageStateEdit" class="btn btn-primary">Edit</button>
          </div>
      </div>

      <!-- FRAGRANCES -->
      <div class="row my-5">
          <h2>Fragrance Inventory</h2>
          <div v-if="pageState == 'edit'">
            <a class="link link-primary" @click="selectAllFragrances">Select All</a> |
            <a class="link link-primary" @click="deselectAllFragrances">Deselect All</a>
          </div>
          <ul class="list-unstyled card-columns">
            <li v-for="(fragrance) in allFragrances" :key="fragrance.id" class="p-2 mb-2">
                <div class="form-check form-switch">
                    <!-- Checkbox -->
                    <input class="form-check-input" :id="'flexSwitchCheckDefault_' + fragrance.id" type="checkbox" role="switch" @change="toggleFragranceSelection(fragrance.id)" :checked="isFragranceSelected(fragrance.id)" :disabled="pageState == 'view'">
                    <!-- Name of Fragrance -->
                    <label class="form-check-label notranslate" :for="'flexSwitchCheckDefault_' + fragrance.id">{{ fragrance.name }}</label>
                </div>
            </li>
        </ul>
      </div>
      <!-- END FRAGRANCES -->


     
  
      <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
    </section>
  </template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import errorMessageModal from '../../components/error-message-modal.vue';
import {mapGetters } from 'vuex';

export default {
    name: 'ProviderInventory',
    components: {
        breadcrumbVue,
        errorMessageModal
    },
    data() {
    return {
        crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }
        ],
        pageState: 'view',
        errorMessages: [],
        errorTitle: 'Error Adding Provider',
        isLoading: false,
        isSaving: false,
        isDeleting: false,
        showSaveSuccess: false,
        showSaveError: false,
        showInvalidForm: false,
        isDeleteModalVisible: false,
        currentProvider: {
            fragrances: []
        },
        currentName: null,
        currentId: null,
        isEnforceUploads: false
    }
  },
  created() {
    this.populateProvider();
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
        allFragrances: 'fragrancesExtendedModule/getFragrancesExtended',
        getCurrentUserInRole: 'getCurrentUserInRole',
    }),  
  },
  methods: {
    populateProvider() {
        this.currentProvider = this.$store.getters['providersModule/getProviderById'](this.$route.params.providerId);
        if (this.currentProvider.fragrances == null) {
            this.currentProvider.fragrances = [];
        }

        var newCrumb = { name: this.currentProvider.name, url: '/providers/' + this.$route.params.providerId };
        this.crumbs.push(newCrumb);
        return;
    },
    selectAllFragrances() {
        this.currentProvider.fragrances = this.allFragrances.map((allFragrances) => ({
            id: allFragrances.id
        }));
    },
    deselectAllFragrances() {
        this.currentProvider.fragrances = [];
    },
    isFragranceSelected(fragranceId) {
        if (this.currentProvider.fragrances != null && this.currentProvider.fragrances.length > 0) {
            let index = this.currentProvider.fragrances.findIndex(f => f.id == fragranceId);
            if (index > -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    toggleFragranceSelection(fragranceId) { 
        let index = this.currentProvider.fragrances.findIndex(f => f.id == fragranceId);
        if (index > -1) {
            // it's found - remove it
            this.currentProvider.fragrances.splice(index, 1);
        } else {
            // add the fragrance
            this.currentProvider.fragrances.push({id: fragranceId});
        }
    },
    showPageStateEdit() {
        this.pageState = 'edit';
        this.showSaveSuccess = false; 
    },
    showPageStateView() {
        this.pageState = 'view';
    },
    async validateAndSaveProvider() {
        this.errorMessages = [];
        var isValid = true;
        // check for id
        if (this.currentProvider.id == null || this.currentProvider.id.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Id is required.');
        }
        // check for name
        if (this.currentProvider.name == null || this.currentProvider.name.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Name is required.');
        }

        if (this.currentProvider.fragrances == null || this.currentProvider.fragrances.length < 1) {
            isValid = false;
            this.errorMessages.push('At least 1 Fragrances is required.');
        }

        if (isValid == true) {
            this.isSaving = true;
            // update
            await this.$store.dispatch('providersModule/updateProvider', this.currentProvider).then(() => {
            // add saved success message
            this.showSaveSuccess = true;
            this.isSaving = false;
            this.pageState = 'view';
            });
 
        } else {
            // show error message
            this.showInvalidForm = true;
        }
    },
    onCloseErrorMessageModal() {
        this.showInvalidForm = false;
        this.errorMessages = [];
    }
  }
}

</script>
