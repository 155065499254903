<template>
  <div v-if="!isLoading">
    <GoogleTranslateSelect
      default-page-language-code="en"
      :fetch-browser-language="false"
      :default-language-code="selectedLanguage"      
      @select="handleGoogleTranslateSelect"
    />
  </div>
</template>

<script>
import { GoogleTranslateSelect } from "@google-translate-select/vue3";
import axios from 'axios';
import { mapGetters } from "vuex";
export default {
  name: "GoogleTranslate",
  components: {
    GoogleTranslateSelect,
  },
  data() {
    return {
      isLoading: true,
      isFirstTimeLoading:true,
      currentExtendedUser: {},
      selectedLanguage: "",
      userId: "",
      currentLanguage : ""
    };
  },
  mounted() {
    this.initUserInfo();
  },
  computed: {
    ...mapGetters({
      account: "getCurrentUser",
      isSignedInUser: 'isSignedInUser',
      getUserByEmailAddress: "usersModule/getUserByEmailAddress",
    }),
  },
  methods: {
    async initUserInfo() {
      if (this.account && this.account.username) {        
        this.currentExtendedUser = this.getUserByEmailAddress(
          this.account?.username
        );
        if(this.currentExtendedUser)
          this.loadLanguageSelectionData();
        else
          {
            await this.$store.dispatch("usersModule/fetchAllUsers").then(() => {
              this.currentExtendedUser = this.getUserByEmailAddress(this.account?.username);
              this.loadLanguageSelectionData();
            });
          }
      }
      else
        console.log("account info not found:");
    },
    loadLanguageSelectionData(){
        this.userId = this.currentExtendedUser?.id;
        this.deleteCookie('googtrans');
        this.selectedLanguage = this.currentExtendedUser?.language || 'en';
        this.currentLanguage = this.currentExtendedUser?.language || 'en';
        this.isLoading = false;
    },
    handleGoogleTranslateSelect(language) {
      if(this.isSignedInUser == false)
        return;
      if(this.isFirstTimeLoading)
        this.isFirstTimeLoading = false;
      else if (this.userId && language && (this.currentLanguage != language.code)) {        
        const payload = {
          userId: this.userId,
          language: language.code,
        };
        axios.post('https://prolitecdataservices.azurewebsites.net/api/vseCreateUserLanguageHistory', payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': 'xxjlmZvsGRHdMuE3Sj8Wxz1xd6548rycKjEtQv1aytPGAzFuuToOyA==',
            'Referrer-Policy': 'origin-when-cross-origin',
          }
        })
        .then(() => {
          this.currentLanguage = language.code;
          //##############################################
          const payloadToUpdateStore = {
          languageCode: language.code,
          userId: this.currentExtendedUser.id
        };        
          this.$store.dispatch("usersModule/updateUserLanguageCode",payloadToUpdateStore).then(() => {
                this.currentExtendedUser.language = language.code;            
            });            
          //##############################################
        })
        .catch(error => {
        console.error("Error saving language", error);
        });
      }
    },
    deleteCookie(cookieName) {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  },
};
</script>
